import axios from "axios";

const token = localStorage.getItem("token");


// user login
const userLogin = async (data) => {
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    const apiVersion = process.env.REACT_APP_API_VERSION;
    const path = backendUrl+apiVersion+'/login';
  const config = {
    headers: { Accept: `application/json` },
  };
  let response;
  try {
    response = await axios.post(path, data, config);
  } catch (error) {
    response = error.response;
  }
  if(response.status != 200){
    
    throw new Error(JSON.stringify(response.data));
  }
  return response.data;
};

const registration = async (data) => {
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    const apiVersion = process.env.REACT_APP_API_VERSION;
    const path = backendUrl+apiVersion+'/register';
  const config = {
    headers: { Accept: `application/json` },
  };
  let response;
  try {
    response = await axios.post(path, data, config);
  } catch (error) {
    response = error.response;
  }
  if(response.status != 200){
    throw new Error(JSON.stringify(response.data));
  }
  return response.data;
};

// get user
const getUser = async (token) => {
  const backendUrl = process.env.REACT_APP_API_ENDPOINT;
  const apiVersion = process.env.REACT_APP_API_VERSION;  
  const path = backendUrl+apiVersion+'/user';
  let response;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    response = await axios.get(path, config);

  } catch (error) {
    response = error.response;
  }
  return response.data;
};

// logout user
const logout = async (token) => {
  const backendUrl = process.env.REACT_APP_API_ENDPOINT;
  const apiVersion = process.env.REACT_APP_API_VERSION;
  const path = backendUrl+apiVersion+'/logout';
  let response;
  const config = {
    headers: { Authorization: `Bearer ${token}` , Accept: `application/json` },
  };
  let body = { body: {}}
  try {
    response = await axios.post(path, body, config);
  } catch (error) {
    response = error.response;
  }
  return response.data;
};



const authService = {
  userLogin,
  getUser,
  logout,
  registration
};

export default authService;
