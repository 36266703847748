import React, { FC , useRef } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { useSelector , useDispatch } from "react-redux";
import { registration } from "features/authentication/authSlice";
import { useEffect , useState } from "react";
import { useNavigate } from "react-router-dom";

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp = ({ className = "" }) => {

  const email = useRef(null);
  const userName = useRef(null);
  const password = useRef(null);
  const securityQues = useRef(null)
  const securityAns = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {authenticated, error} = useSelector((state) => state.auth)
  const [message, setMessage] = useState("")

   
  const registrationHandler = async ()=>{
    let body = {
      name : userName.current.value,
      password: password.current.value,
      email: email.current.value,
      security_question: securityQues.current.value,
      security_answer: securityAns.current.value,
    }
    console.log(body)
    dispatch(registration(body))
  }

   useEffect(() => {
      if (error) {
          setMessage(error.message)
      }
      if(authenticated){
          navigate('/customer/account');
      }

    }, [error, authenticated]);

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Dainch</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <div className="grid grid-cols-1 gap-6">
            <span className="text-xs text-red-600 text-center">
                {message}
              </span>
           <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Name
              </span>
              <Input
                type="text"
                placeholder="Jon Doe"
                className="mt-1"
                ref={userName}
              />
            </label>
            <label>
              <span className="text-neutral-800 dark:text-neutral-200">
                Write Security question
              </span>
              <Input
                type="text"
                placeholder="Whats your favorite pet ?"
                className="mt-1"
                ref={securityQues}
              />
            </label>
            <label>
              <span className="text-neutral-800 dark:text-neutral-200">
                Write Security Answer
              </span>
              <Input
                type="text"
                placeholder="Racoon"
                className="mt-1"
                ref={securityAns}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                ref={email}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" ref={password} />
            </label>
            <ButtonPrimary onClick={registrationHandler} >Continue</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-green-600" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
