import useDestroy from "hooks/useDestroy";
import React, { useEffect, useState } from "react";


class Feature {
  constructor(id, name, value) {
    this.id = id
    this.name = name;
    this.value = value;
  }

  getName() {
    return this.name;
  }

  getValue() {
    return this.value;
  }

  setName(name) {
    this.name = name;
  }

  setValue(value) {
    this.value = value;
  }

  getFormData(){
    return {name : this.name, value: this.value}
  }

  // setId(id)   {
  //   this.id = id
  // }
}



// "features": "[{\"name\":\"Battery\",\"value\":\"1 hours\"}]"

const FeaturesInput = ({name , handleChange, oldFeatures , productId}) => {
  const  [destroy, apiDelete] = useDestroy()
  const [features, setFeatures] = useState([]);
  const apiVersion = process.env.REACT_APP_API_VERSION;
  

  const handleAddButtonClick = () => {
    const newFeatures = new Feature(null, "", "");
    setFeatures([...features, newFeatures]);
  };
  const handleDelete = async(index , id) => {
   
    const updatedFeatures = features.filter((_, i) => i !== index);
    if(id == null){
       setFeatures(updatedFeatures);
    }else{
       await apiDelete(apiVersion+'/admin/products/'+productId+'/features/'+id).then( ()=>{
         setFeatures(updatedFeatures);
       } )
      
    }
    handleChange({
      id: oldFeatures?.id ? oldFeatures?.id : null,
      name: name,
      features: updatedFeatures,
    });
  };

  
  useEffect(() => {
    var oldfeatures = [];
    if (oldFeatures) {
      oldFeatures?.features.map((feature, index) => {
        
       const newFeatures = new Feature(feature.id ?? null, feature.name, feature.value)
       oldfeatures.push(newFeatures);
     })
      setFeatures([...features, ...oldfeatures])

      handleChange(oldfeatures)
      // handleChange({
      //   "name" : name,
      //   "features": oldfeatures
      //  });

    }
    

    
  }, [])
  
  const handleChangeLabel = (event, index) => {
    
    const updatedAttribute = features[index];
    console.log(updatedAttribute, event.target.value)
    updatedAttribute.setName(event.target.value);
    setFeatures([...features]);
    handleChange(features);
  };
   const handleChangeValue = (event, index) => {
    const updatedAttribute = features[index];

    updatedAttribute.setValue(event.target.value);
    setFeatures([...features]);
    console.log(features)
    handleChange(features);
    
  };
  

  return (
    <form className="flex flex-col gap-4 border border-gray-300 rounded-md">
      <div className="">

        <div className="border-b border-gray-300 p-2 mb-2">
          <p className="font-medium text-xl">{name} :</p>
        </div>

        {features.map((feature, index) => (
          <div key={index} className="flex items-center gap-4 mb-3 p-2">
            <div className="flex items-center gap-3 w-full">
              <label htmlFor={`feature-${index}`} className="text-base font-medium whitespace-nowrap">Label :</label>
              <input 
              className="border border-gray-300 rounded-md w-full"
                type="text"
                name={`feature-${index}`}
                value={feature.name}
                onChange={(event) => handleChangeLabel(event, index)}
              />
            </div>

            <div className="flex items-center gap-3 w-full">
              <label htmlFor={`feature-value-${index}`} className="text-base font-medium whitespace-nowrap">Value :</label>
              <input
                className="border border-gray-300 rounded-md w-full"
                type="text"
                name={`feature-value-${index}`}
                value={feature.value}
                onChange={(event) => handleChangeValue(event, index)}
              />
            </div>

            <button className="border border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-4 py-2 rounded-md transition-none duration-200" type="button" onClick={()=>handleDelete(index , feature.id )} >  Delete</button>
            
          </div>
        ))}
      </div>

      <button className="border border-indigo-600 bg-indigo-600 text-white px-4 py-2 font-medium rounded-md w-auto mx-2 mb-2 hover:bg-white hover:text-indigo-600 transition-all duration-200" type="button" onClick={handleAddButtonClick}>
        Add Features
      </button>
       

    </form>
  );
};

export default FeaturesInput;