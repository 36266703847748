import React, { useEffect, useRef, useState } from 'react'
import Input from 'shared/Input/Input'
import Dropdown from 'components/FormInput/Dropdown'
import useGet from 'hooks/useGet';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import useShow from 'hooks/useShow';
import useDestroy from 'hooks/useDestroy';
import InputText from 'components/FormInput/InputText';
import MultipleSelect from 'components/FormInput/MultipleSelect';
import { useNavigate } from 'react-router-dom';
import ProductContainer from 'containers/ProductPage/ProductContainer';


export default function CreateProduct() {

    const apiVersion = process.env.REACT_APP_API_VERSION;
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;

    const navigate = useNavigate()


    const pageData= {
      title: "Create Product"
    }

    const inputs ={
      name:null,
      description:null,

    }

    const [submitData, handleClick] = usePost("", inputs)

    var brandOptions = [{ key: 1, value: 'good' }];






  const handleInputChange = (value, label) => {
    inputs[label] = value
  };

  const options = [
    { key: 1, value: 'On' },
    { key: 2, value: 'Off' },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleOptionChange = (key, value) => {
    setSelectedOption(value);
  };

  const submit =  async () => {
    // handleClick(inputs)

     await handleClick(apiVersion+'/admin/products', inputs).then((response) => {
          if (response.status == 200) {
            
            navigate('/admin/products/edit/image/'+response.res.data.id)
            // console.log(response)
          }
        })
};

  

  const tabs = [
    { name: 'Create Product', href: '#', current: true },
    { name: 'Image Upload', href: '#', current: false },
    { name: 'Attribute', href: '#', current: false },
    { name: 'Variation', href: '#', current: false },
    { name: 'Features', href: '#', current: false },
  ]
  

  return (
    <ProductContainer title="Create Product" tabData={tabs}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6" >
            <InputText onInputChange={handleInputChange} title="Product name" label="name" type="text" error="" oldValue="" />



            <InputText onInputChange={handleInputChange} title="Product description" label="description" type="text" error="" oldValue="" />
          </div>

          <button  onClick={() => submit()} className='p-2 bg-blue-400 hover:bg-blue-700 transition-all duration-150 text-white rounded-lg mt-6 px-5 uppercase font-medium shadow-md'>Save</button>

    </ProductContainer>
  )
}
