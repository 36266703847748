import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/rightLargeImg.png";
import rightLargeImgDark from "images/rightLargeImgDark.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionPromo1Props {
  className?: string;
}

const SectionPromo1: FC<SectionPromo1Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionPromo1 relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionPromo1"
    >
      <div className="relative flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <p className="text-lg font-medium text-gray-600">Elevate Your Wardrobe</p>
        <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight">
          Timeless Elegance in Every Stitch
        </h2>
        <span className="block mt-6 text-slate-500 dark:text-slate-400 ">
                  </span>
        
      </div>
      <div className="relative flex-1 max-w-xl lg:max-w-none">
        <NcImage containerClassName="block dark:hidden" src={rightImgDemo} />
        <NcImage
          containerClassName="hidden dark:block"
          src={rightLargeImgDark}
        />
      </div>
    </div>
  );
};

export default SectionPromo1;
