import React, { useEffect, useRef, useState } from 'react'
import Input from 'shared/Input/Input'
import Dropdown from 'components/FormInput/Dropdown'
import useGet from 'hooks/useGet';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import useShow from 'hooks/useShow';
import useDestroy from 'hooks/useDestroy';
import InputText from 'components/FormInput/InputText';
import MultipleSelect from 'components/FormInput/MultipleSelect';
import { useNavigate, useParams } from 'react-router-dom';
import ProductContainer from 'containers/ProductPage/ProductContainer';


export default function Description() {

    const apiVersion = process.env.REACT_APP_API_VERSION;
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    const {productId} = useParams();
    const [submitData, handleClick] = usePost("", {})
    const [index, indexLoading, fetchDescription] = useIndex('')

    const navigate = useNavigate()

    const pageData= {
      title: "Edit Product"
    }

    // const inputs ={
    //   name:null,
    //   description:null,

    // }

    const [inputs, setInputs] = useState({
      _method: "PUT",
      name:null,
      description:null,

    })


  const tabs = [
    { name: 'Edit Product', href: '/admin/products/edit/'+productId, current: true },
    { name: 'Image Upload', href: '/admin/products/edit/image/'+productId , current: false },
    { name: 'Attribute', href: '/admin/products/edit/attribute/'+productId, current: false },
    { name: 'Variation', href: '/admin/products/edit/variation/'+productId, current: false },
    { name: 'Features', href: '/admin/products/edit/feature/'+productId, current: false },
  ]




  var brandOptions = [{ key: 1, value: 'good' }];

  const handleInputChange = (value, label) => {
    inputs[label] = value
    setInputs({...inputs})
  };

  const options = [
    { key: 1, value: 'On' },
    { key: 2, value: 'Off' },
  ];

  let path = apiVersion+'/admin/products'

  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleOptionChange = (key, value) => {
    setSelectedOption(value);
  };

  const submit =  async (exit) => {
    // console.log(inputs)
    await handleClick(path+'/'+productId, inputs).then((response) => {
      if (response.status == 200) {
        if (exit) {
          navigate('/admin/products')
        }else{
          navigate('/admin/products/edit/image/'+productId)
        }
        
      }
    })


  };


  
  const [loading, setLoading] = useState(null)

    const dataLoad = async () => {
      setLoading(true)
      await fetchDescription(path+'/'+productId).then(response => {
          setLoading(false)
        if(response){
          inputs.name = response.res.data.name 
          inputs.description = response.res.data.description
          setInputs({...inputs})
        }
      })
      .catch(error => {
          console.error('Error:', error);
      });

    }

    useEffect(() => {
      if (loading == null) {
        console.log('asdfsdf')
        dataLoad()
      }
    }, [])
  

  return (
    <ProductContainer title={pageData.title} tabData={tabs}>
          <div >
            {/* {
              !loading && ( */}
                <div className="grid grid-cols-4 gap-6" >
                  <InputText onInputChange={handleInputChange} title="Product name" label="name" type="text" error="" oldValue={inputs.name ? inputs.name: ""} />


                  <InputText onInputChange={handleInputChange} title="Product description" label="description" type="text" error="" oldValue={inputs.description ? inputs.description : "" } />
                  
                </div> 
              {/* )
            } */}

          </div>

          <div className='flex gap-2'>
            <button  onClick={() => submit(false)} className='p-2 bg-blue-400 hover:bg-blue-700 transition-all duration-150 text-white rounded-lg mt-6 px-5 uppercase font-medium shadow-md'>Save</button>
            <button  onClick={() => submit(true)} className='p-2 bg-blue-400 hover:bg-blue-700 transition-all duration-150 text-white rounded-lg mt-6 px-5 uppercase font-medium shadow-md'>Save & Exit</button>
            
          </div>

    </ProductContainer>
  )
}
