// import { useAuthStore } from '@/stores/authStore';
// import {useToastStore} from '~~/stores/toastStore';

import { networkGet, networkPost } from "utils/network";
import { useSelector, useDispatch } from 'react-redux'
import { setUser, getUser } from 'features/auth/authSlice'

export const useCrud = () => {
 
  const apiVersion = process.env.REACT_APP_API_VERSION;
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)



  async function get({path}){

    let response  = await networkGet({
      method: 'GET',
      path:path,
      heads:{
          "Accept": "application/json"
      },
    });
    return response
  }
  async function index({path}){
   
    let response  = await networkGet({
      method: 'GET',
      path:path,
      heads:{
          Authorization: `Bearer ${user.token}`
      },
    });
    return response
  }

// async function store({body,path}){
    
//     let response  = await networkPost({
//           method: 'Post',
//           path:path,
//           heads:{
//               Authorization: `Bearer ${authStore.auth.token}`
//           },
//           body: body
//       });
//     return response;
//   }
  
  // done 



  // async function show({path}){
  //   const authStore = useAuthStore();
  //   const toastStore = useToastStore()
  //   const {
  //       open,
  //       close
  //   } = toastStore
  //   let response  = await networkGet({
  //       method: 'GET',
  //       path:path,
  //       heads:{
  //           Authorization: `Bearer ${authStore.auth.token}`,
  //           'Accept' : 'application/json'
  //       },
  //   });
  //   toast({status:response.status,message:response.message})
  //   return response
  // }

  // async function update({body,path}){
  //   const authStore = useAuthStore();
  //   const toastStore = useToastStore()
  //   const {
  //       open,
  //       close
  //   } = toastStore
  //   let response  = await networkPost({
  //       method: 'PUT',
  //       path:path,
  //       heads:{
  //           Authorization: `Bearer ${authStore.auth.token}`
  //       },
  //       body: body
  //     });

  //   toast({status:response.status,message:response.message});
  //   return response;
  // }
  // async function destroy({path}){
  //   const authStore = useAuthStore();
  //   const toastStore = useToastStore()
  //   const {
  //       open,
  //       close
  //   } = toastStore
  //   let response  = await networkGet({
  //     method: 'DELETE',
  //     path:path,
  //     heads:{
  //         Authorization: `Bearer ${authStore.auth.token}`,
  //         "Accept": "application/json"
  //     },
  //   });
  //   toast({status:response.status,message:response.message});
  //   return response;
  // }
  
  // function toast({status,message}){
    
  //   const toastStore = useToastStore()
  //   const {
  //       open,
  //       close
  //   } = toastStore
  //   if (status === 200) {
  //     toastStore.open('success' ,'success', message )
  //   } else if(status === 422){
  //     toastStore.open('danger' ,'Failed', message )
  //   }
  // }

  return {
    get,
    index,
    // store,
    // show,
    // update,
    // destroy
  }
}








































// import { useAuthStore } from '@/stores/authStore';
// import {useToastStore} from '~~/stores/toastStore';

// const authStore = useAuthStore();

// const toastStore = useToastStore()
// const {
//     open,
//     close
// } = toastStore

// export const useCrud = () => {
//   // const { apiVersion } = useRuntimeConfig().public;
//   async function index({path}){
//     let response  = await networkGet({
//       method: 'GET',
//       path:path,
//       heads:{
//           Authorization: `Bearer ${authStore.auth.token}`,
//           "Accept": "application/json"
//       },
//     });
//     toast({status:response.status,message:response.message})
//     return response
//   }
//   async function show({path}){
//     let response  = await networkGet({
//         method: 'GET',
//         path:path,
//         heads:{
//             Authorization: `Bearer ${authStore.auth.token}`,
//             'Accept' : 'application/json'
//         },
//     });
//     toast({status:response.status,message:response.message})
//     return response
//   }
//   async function store({body,path}){
//     let response  = await networkPost({
//           method: 'Post',
//           path:path,
//           heads:{
//               Authorization: `Bearer ${authStore.auth.token}`
//           },
//           body: body
//       });
//     toast({status:response.status,message:response.message});
//     return response;
//   }
//   async function update({body,path}){
//     let response  = await networkPost({
//         method: 'PUT',
//         path:path,
//         heads:{
//             Authorization: `Bearer ${authStore.auth.token}`
//         },
//         body: body
//       });

//     toast({status:response.status,message:response.message});
//     return response;
//   }
//   async function destroy({path}){
//     let response  = networkGet({
//       method: 'DELETE',
//       path:path,
//       heads:{
//           Authorization: `Bearer ${authStore.auth.token}`,
//           "Accept": "application/json"
//       },
//     });
//     toast({status:response.status,message:response.message});
//     return response;
//   }
  
//   function toast({status,message}){
//     if (status === 200) {
//       toastStore.open('success' ,'success', message )
//     } else if(status === 422){
//       toastStore.open('danger' ,'Failed', message )
//     }
//   }

//   return {
//     index,
//     show,
//     store,
//     update,
//     destroy
//   }
// }
