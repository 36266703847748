
import axios from 'axios';


export const  networkPost = async({method , path ,heads , body }) => {
    
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    var errorMessage = "";
    var response;
    var errors= [];
    let flag = 200;

    if(method == 'POST'){
      response=await axios.post(backendUrl+path, body, {
            headers: heads,
        })
        .then((response) => response)
        .catch(function (error) {
            console.log(error.response)
            flag = 422;
            errorMessage = error.response.data.message;
            errors = error.response.data.errors;
        });
    }else if(method == 'PUT'){
        await axios.put(backendUrl+path, body, {
            headers: heads,
        })
        .then(function (response) {
        console.log(response);
        })
        .catch(function (error) {
            flag = 422;
            errorMessage = error.response._data.message;
            errors = error.response._data.errors;
        });
    }
    


    if(flag == 422){
        return {
            'status' : 422,
            'errors': errors,
            'message': errorMessage,
        }
    }else{
        return {
            'status' : 200,
            'res': response,
            'message': "Network Responded Successfully",
        }
    }
}



export const  networkGet = async({method , path ,heads }) => {
    
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    var errorMessage = "Error Internal";
    var response;
    var errors= [];
    let flag = 200;

    if (method == 'GET') {
           response =  await axios.get(backendUrl+path, {
                headers: heads
            }).then((response) => response)
            .catch(function (error) {
                flag = 422;
                errorMessage = error.response._data.message;
                errors = error.response._data.errors;
            });
    }else if(method == 'DELETE'){
            response =  await axios.delete(backendUrl+path, {
                headers: heads
            }).then((response) => response)
            .catch(function (error) {
                flag = 422;
                errorMessage = error.response._data.message;
                errors = error.response._data.errors;
            });
    }


    // await fetch(backendUrl+path, {
    // method: method,
    // headers: heads
    // })
    // .then((response) => response.json())
    // .then((json) =>  response = json )
    // .catch((error) => {
    //     flag = 422;
    //     errors = error;
    // });

//       .then((response) => response.json())
//   .then((json) => console.log(json));

    // await $fetch(path, {
    //     method: method,
    //     baseURL: backendUrl,
    //     headers: heads,
        
    // }).then((res)=>{
    //     response = res;
    // }).catch((error)=>{
    //     flag = 422;
    //     errors = error;
    // });
    if(flag == 422){
        return {
            'status' : 422,
            'errors': errors,
            'message': errorMessage,
        }
    }else{
        return {
            'status' : 200,
            'res': response,
            'message': "Network Responded Successfully",
        }
    }
}

export const  networkImage = (imagePath)=>{
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    return backendUrl+'/storage/'+imagePath;
}
