import React, { useEffect } from "react";
import UserRouter from "routers/index";
import ScrollToTop from "routers/ScrollToTop";
import SiteHeader from "containers/SiteHeader";
import Footer from "shared/Footer/Footer";
import { Toaster } from "react-hot-toast";

import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { clearError } from "features/authentication/authSlice";

// Pass the child props
export default function Layout({ children }) {

  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearError())
  }, [location]);
  
  return (
    <div>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Outlet />
      
      <Footer />
    </div>
  );
}