import AttributeInput from 'components/FormInput/AttributeInput';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import {Route, Link, Routes, useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FormWithAddButton from 'components/FormInput/FormWithAddButton';
import ProductContainer from 'containers/ProductPage/ProductContainer';




export default function Attribute() {
    const [formData, submitAttribute] = usePost('', {})
    const [productAttribute, attributeLoading, fetchAttribute] = useIndex('')
    const navigate = useNavigate()
    
    const {productId} = useParams();
    const pageData= {
        title: "Edit Attribute"
    }

    const tabs = [
        { name: 'Edit Product', href: '/admin/products/edit/'+productId, current: false },
        { name: 'Image Upload', href: '/admin/products/edit/image/'+productId , current: false },
        { name: 'Attribute', href: '/admin/products/edit/attribute/'+productId, current: true },
        { name: 'Variation', href: '/admin/products/edit/variation/'+productId, current: false },
        { name: 'Features', href: '/admin/products/edit/feature/'+productId, current: false },
    ]


    const apiVersion = process.env.REACT_APP_API_VERSION;
    const path = apiVersion + "/admin/products/" + productId + "/attributes";

    const [oldAttributes , setOldAttributes] = useState([])

    // useEffect(() => {
    //   callOldValues('admin/products/'+productId+'/attributes').then((response) => {
    //       console.log(response)
    //   })
    // }, [])


    let body = [];
    const [saveValues, setSaveValues] = useState(false)
    const [errorMgs, setErrorMgs] = useState("")
    let allAttributes = []
    const [skipStep, setSkipStep] = useState(false)
    
    const [loading, setLoading] = useState(null)

    let colorAttribute = {}
    let sizeAttribute = {}

    const [allAttribute, setAllAttribute] = useState([])

    const save = async () => {
        
        if (allAttribute.length > 0) {
            colorAttribute["id"] = allAttribute[0].id
            sizeAttribute["id"]   = allAttribute[1].id
        }else{

            colorAttribute["id"] = null
            sizeAttribute["id"]   = null
        }

        let attributes = JSON.stringify([colorAttribute, sizeAttribute])
        console.log('attributes',attributes);
        submitAttribute(path, {"attributes":attributes}).then((response) => {
            if (response.status == 200) {
                
                navigate('/admin/products/edit/variation/'+productId)
            }
        })
    }


  const dataLoad = async () => {
    setLoading(true)
    await fetchAttribute(apiVersion + "/admin/products/" + productId + "/attributes").then(response => {

        if (response.res.data.product_attributes.length > 0) {
            setAllAttribute(response.res.data.product_attributes )
        }
        setLoading(false)        
    })
    .catch(error => {
        console.error('Error:', error);
    });

  }

    useEffect(() => {
        if (allAttributes.length == 0) {
            dataLoad()
        }else{
        
        }
    }, [attributeLoading])


    const handleAttributeColor= (val) =>{
        colorAttribute = val
    
    }

    const handleAttributeSize= (val) =>{
        sizeAttribute = val 
    
    }


    return (
        <ProductContainer title="Add Attributes" tabData={tabs}>

            <div className="w-full" >

                {
                    !loading ? 
                    <>

                        <div className="w-full grid grid-cols-2 items-start gap-4">
                            <FormWithAddButton class="w-full" name="Size" productId={productId} handleChange={ handleAttributeSize} oldAttribute={allAttribute[1]}/>
                            <FormWithAddButton class="w-full" name="Color" productId={productId} handleChange={ handleAttributeColor} oldAttribute={allAttribute[0]}/>

                        </div>

                        <div className='flex justify-between'>
                            <button className='bg-blue-600 px-4 py-2 border border-blue-600 text-white font-medium rounded-md mt-8 indigo-600 hover:bg-white hover:text-blue-600 transition-all duration-200' onClick={() => save()}>Submit</button>

                            {/* <Link to={'/admin/products/edit/variation/'+productId} className='bg-blue-600 px-4 py-2 text-white font-medium rounded-md mt-8'>Next Page</Link> */}
                        </div>
                    </>
                    : 
                    <>
                    </>
                }
                

            </div>
        </ProductContainer>
    )

}