import React from 'react'
import { Link } from 'react-router-dom'
import { networkImage } from 'utils/network'
export default function Details({detailInfo, title, backLink}) {

  return (
  <div className="overflow-hidden bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6 flex justify-between">
      <h3 className="text-base font-semibold leading-6 text-darkGray/90">
        {title}
      </h3>

      <Link to={backLink} className='p-2 bg-blue-400 hover:bg-blue-700 transition-all duration-150 text-white rounded-lg'>Go Back</Link>
    </div>
    <div className="">
        {
            detailInfo.map((info, index) => (
        <dl key={index}>


            {
                info.type == 'text' ? 
                <div
                v-if="item.type == 'text'"
                className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt className="text-sm font-medium text-darkGray/60 uppercase">
                      {info.key}
                  </dt>
                  <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
                      {info.value}
                  </dd>
                </div>
                :
                <div
                v-if="item.type == 'image'"
                className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                    <dt className="text-sm font-medium text-darkGray/60 uppercase">
                    {info.key }
                    </dt>
                    <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
                        <img
                        src={networkImage(info.value)}
                        className="w-20 h-20 flex-shrink-0 object-fit"
                        />
                    </dd>
                </div>

            }

        </dl>
            ))
        }

      {/* <dl v-for="item in props.data" :key="item.key">
        <div
          v-if="item.type == 'text'"
          className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            {{ $t(item.key) }}
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            {{ item.value }}
          </dd>
        </div>
        <div
          v-if="item.type == 'image'"
          className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            {{ item.key }}
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            <img
              :src="networkImage(item.value)"
              className="w-20 h-20 flex-shrink-0 object-fit"
            />
          </dd>
        </div>
      </dl> */}
    </div>
  </div>
  )
}
