import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import useIndex from "hooks/useIndex";
import usePost from "hooks/usePost";
import { useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { networkImage } from "utils/network";
import CommonLayout from "./CommonLayout";

const AccountOrder = () => {

  const apiVersion = process.env.REACT_APP_API_VERSION;
  let path  = apiVersion+"/customer/orders"

  
  const [orders, ordersIsLoading, callOrders] = useIndex('')
  const [allOrder , setAllOrder] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [returnNote, setReturnNote] = useState('')

  const [submitData, handlePost] = usePost("", {})
  const returnApi = apiVersion+'/customer/return-request/'

  useEffect(() => {
    dataLoad(path)
  }, [])

  const dataLoad = (api) => {
    callOrders(api).then((response) => {
      // setTotalPages(response.res.data.last_page)
      console.log(response.res.data.data)
      setAllOrder(response.res.data.data)
    })
  }


  const sendReturnReq = async (api, inputs) => {
        console.log(api, inputs)
    await handlePost(api, inputs).then((response) => {
      if (response.status == 200) {
        
        setOpenModal(false)
        dataLoad(path)
      }
    })
  }
  

  const renderProductItem = (item, index) => {
    const { product , product_variation , name, measurement, quantity,price} = item;
    
    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          {/* product_variation.image ? networkImage(product_variation.image) */}
          <img
            src={product_variation.image ? networkImage(product_variation.image) : networkImage(product.thumbnail_path)}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium line-clamp-1">{name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>{"chest:"+JSON.parse(measurement)['chest']} </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{product_variation.variation_string}</span>
                </p>
              </div>
              <Prices   price={price} className="mt-0.5 ml-2" />
              
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Qty</span>
              <span className="inline-block sm:hidden">x</span>
              <span className="ml-2">{quantity}</span>
            </p>

            {/* <div className="flex">
              <button
                type="button"
                className="font-medium text-indigo-600 dark:text-primary-500 "
              >
                Leave review
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = (orderInfo, index) => {
    if (!allOrder) {
      return (
        <></>
      )
    }else{
      return (
        <div key={index} className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
            <div>
              <p className="text-lg font-semibold">{orderInfo["order_number"]}</p>
              {
                orderInfo['status'] == 'Delivered' && !orderInfo['return_requested_at']?               
                <button onClick={() => {
                setOpenModal(true);
                setReturnNote({
                  orderNo: orderInfo["order_number"]
                })
              }} className="bg-red-600 text-xs text-white px-2 p-1 rounded-lg">Return</button>: <></>
              }

              <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                <span>{orderInfo["created_at"].substring(0, 10)}</span>
                <span className="mx-2">·</span>
                <span className="text-primary-500">Total: {orderInfo.total} </span>
              </p>
            </div>
            <div className="text-xs font-medium border border-green-500  border-2 rounded-lg p-2 text-green-500 ">{orderInfo['status']}</div>
            {/* <div className="mt-3 sm:mt-0">
              <ButtonSecondary
                sizeClass="py-2.5 px-4 sm:px-6"
                fontSize="text-sm font-medium"
              >
                View Order
              </ButtonSecondary>
            </div> */}
          </div>
          <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
            {orderInfo["items"].map(renderProductItem)}
          </div>
        </div>
      );
    }
    
  };

// {
//     "note": "this is the issue"
// }
  const modal = () => {


    // setYourState(prevState => ({
      // setReturnNote({"note": e.target.value})
      // setReturnNote(prevState => ({...prevState, "note": e.target.value}))
    return (
      <div className="fixed top-0 right-0 bg-slate-500/20 h-full w-full flex justify-center items-center">
        <div className="border rounded-lg bg-white  w-96 h-fit p-3 ">
          <div className="border flex justify-center items-center flex-col p-2 px-8 py-10 w-full h-full border-red-700/50">
          <div className="w-full pb-5 text-base font-medium">
            <p>Why you want to return this product?</p>
          </div>
          <label htmlFor="" className="py-2">
            <p className="text-sm text-red-600 pb-3">Note</p>
            <textarea onChange={(e) =>  setReturnNote(prevState => ({...prevState, "note": e.target.value}))} className="rounded-lg" />
          </label>
          <div className="w-full flex justify-between">
            <button onClick={() => sendReturnReq(returnApi+returnNote["orderNo"],{'note': returnNote["note"]})} className="bg-green-700 text-xs text-white p-2 border hover:text-green-700 hover:bg-white transition-all duration-100 uppercase">send request</button>
            <button onClick={() => setOpenModal(false)} className="bg-red-700 text-xs text-white p-2 border hover:text-red-700 hover:bg-white transition-all duration-100 uppercase">Cancel</button>
          </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
          
          
          {
             allOrder?.map((order, index) => (
              renderOrder(order, index)
            ))
          }

          {
            openModal ? modal() : <></>
          }
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
