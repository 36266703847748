import Tab from 'components/Tab/Tab'
import React from 'react'

export default function ProductContainer({title, children, tabData}) {
  return (
    <div>
        <div >
          <div>
            {
              tabData ? <Tab tabs={tabData}/> : <></>
            }
              
          </div>
          <div className='mb-10'>
              <p>{title}</p>
          </div>
          <div>
              {children}
          </div>
        </div>
    </div>
  )
}
