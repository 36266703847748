  import React, { useState } from 'react';
  import { toast, ToastContainer } from 'react-toastify';
  import "react-toastify/dist/ReactToastify.css";

  import { useSelector, useDispatch } from 'react-redux'
import { open, close, insertToastData } from '../../features/toast/toastSlice';

export default function AdminToast() {

  const dispatch = useDispatch();
  const {token} = useSelector((state) => state.auth)

 const notify = (type) => {
      if (type == 'success') {
        toast.success("Success Notification !", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else if (type == 'warn') {
        toast.warn("Warning Notification !", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else if (type == 'error') {
        toast.error("Error Notification !", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else if (type == 'info') {
        toast.info("Info Notification !", {
          position: toast.POSITION.TOP_RIGHT
        });
      }


    };

  return (
        <>
          <button onClick={() => notify('success')}>Notify</button>;
          <ToastContainer />
        </>
  )
}
