import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import useGet from 'hooks/useGet';
import useIndex from 'hooks/useIndex';
import Table from   'components/Table/Table'
import useNetworkImage from 'hooks/useNetworkImage';
import { networkImage } from 'utils/network';
import ActionCell from 'components/Table/ActionCell';
import Pagination from 'components/Pagination/Pagination';
import usePost from 'hooks/usePost';

export default function Index() {
    const apiVersion = process.env.REACT_APP_API_VERSION;
    const [submitData, handlePost] = usePost("", {}) 

    const pageData = {
        title: "Delivery Charges",
        link:   "/admin/delivery-charges/create"
    }
    const crudLink = "/admin/delivery-charges";
    let path = apiVersion + "/admin/delivery-charge-setting";

    const [deliveryCharges, chargeIsLoading, callDeliveryCharges] = useIndex('')
    const [totalPages, setTotalPages] = useState(0)    
    
   
    let tableHeader = [
      { field: "state", title: "state", sortable: true, arrange: true },
      { field: "charge", title: "charge", sortable: false, arrange: false },
    ]


    useEffect(() => {
      dataLoad()
    }, [])


    const dataLoad = () => {
      callDeliveryCharges(path).then((response) => {
        console.log(response.res.data)
        setTotalPages(response.res.data.last_page)
      })
    }

    const pageRefresh = () => {
      callDeliveryCharges(path)
    }

    const changeStatus = (e, id ) => {
      console.log(e.target.value, id)
      let path = apiVersion+'/admin/products/'+id+'/publish'
      handlePost(path, {"status": e.target.value}).then((response) => {
        dataLoad()
      })
    }
  return (
    <div className=''>
        <div className='flex justify-end'>
          
            <Link className='bg-green-700 hover:bg-green-300 hover:text-green-700 text-xs p-2 rounded-lg text-white' to={pageData.link}>Add Delivery Charge</Link>

        </div>

        <div>
          <Table headers={tableHeader} title={pageData.title}   >
                <tbody className="divide-y divide-gray-200 bg-white">


                  {!chargeIsLoading && deliveryCharges?.res.data.data.map((charge, index) => (
                    <tr key={index} >
                      <ActionCell 
                        rowId={charge['id']} 
                        viewLink=''
                        editLink={crudLink + '/edit/' + charge['id']}
                        deleteLink={path+'/'+charge['id']}
                        refresh={pageRefresh} 
                      />
                      
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-center text-gray-900 sm:pl-6">
                        { charge["state"]}
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-center text-gray-900 sm:pl-6">
                        { charge["charge"]}
                      </td>
                      
                    </tr>
                  ))}
                  
                </tbody>
          </Table>
          <div className='flex justify-center pt-8'>

            <Pagination pageRoot="/admin/delivery-charges/page/" totalPages={deliveryCharges?.res.data.last_page}/> 

          </div>
        </div>
    </div>
  )
}
