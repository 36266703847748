import React, { FC } from "react";



const Prices = ({
  className = "",
  price = 33,
  selectedVariation={},
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => {
const currency = process.env.CURRENCY;
  // CURRENCY
  return (
    <div className={`${className}`}>
      <div
        className={`flex items-center border-2 border-green-500 rounded-lg ${contentClass}`}
      >
        <span className="text-green-500 !leading-none">
          {/* ${price.toFixed(2)} */}
          ₹{price}
        </span>
      </div>
      <div
        className="flex items-center  rounded-lg "
      >
        
        {
          Object.keys(selectedVariation).length > 0 ?
          <span className="text-green-500 !leading-none">
          <strike>{selectedVariation.discounted_price ? "₹"+selectedVariation.price : ""}</strike>
          {/* {JSON.stringify(selectedVariation.discounted_price)} */}
        </span>
        : 
        <></>
        }
      </div>
    </div>
  );
};

export default Prices;
