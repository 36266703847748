import Details from 'components/Details/Details';
import OrderDetails from 'components/Details/OrderDetails';
import useIndex from 'hooks/useIndex';
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { networkImage } from 'utils/network';

export default function OrderView() {
    const { orderId } = useParams();
    const apiVersion = process.env.REACT_APP_API_VERSION;
    const pageData = {
        title: "Order Details",
        link:   "/admin/orders/",
    }
    const [details, setDetails] = useState([])

    let path = apiVersion + "/admin/orders/"+orderId;

    const [order, orderIsLoading, callOrder] = useIndex(path)

    useEffect(() => {
       
      orderDetailsLoad()

    }, [orderIsLoading])


    const orderDetailsLoad = () => {
      if (!order) {
            callOrder(path).then((response) => {
                console.log(response.res.data)
            })
        }else{

            setDetails([
                {'key': "order_number" , 'value' : order.res.data.order_number , 'type' : 'text' },
                {'key': "return_date" , 'value' : order.res.data.return_date , 'type' : 'text' },
                {'key': "status" , 'value' : order.res.data.status , 'type' : 'text' },
                {'key': "created_at" , 'value' : order.res.data.created_at , 'type' : 'text' },
                {'key': "total" , 'value' : order.res.data.total , 'type' : 'text' },
                {'key': "sub_total" , 'value' : order.res.data.sub_total , 'type' : 'text' },
                {'key': "delivery_charge" , 'value' : order.res.data.delivery_charge , 'type' : 'text' },
                {'key': "order_number" , 'value' : order.res.data.order_number , 'type' : 'text' },
                {'key': "pin_code" , 'value' : order.res.data.pin_code , 'type' : 'text' },
                {'key': "Address" , 'value' : order.res.data.address , 'type' : 'text' },
                {'key': "State" , 'value' : order.res.data.state , 'type' : 'text' },

                

            ])

            }
    }

    const renderProduct = (item, key) => {
        const {product, name , product_variation, measurement} = item
        console.log(product_variation)
        return (
        <div
          key={key}
          className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            Product Image
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            {/* variationRaw.image ? networkImage(variationRaw.image) : networkImage(product.thumbnail_path) */}
            <img
            src={product_variation.image ? networkImage(product_variation.image) : networkImage(product.thumbnail_path)} 
              className="w-20 h-20 flex-shrink-0 object-fit"
            />
          </dd>
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            Product Name
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            
            <p>{name}</p>
          </dd>
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            Product Variation
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            
            <p>{product_variation.variation_string}</p>
          </dd>
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            Product Measurement
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            <div className='grid grid-cols-2 '>
              <p className='text-sm border text-center'>Shoulder</p>
              <p className='text-sm border text-center'>{JSON.parse(measurement)["shoulder"]}</p>


              <p className='text-sm border text-center'>Waist</p>
              <p className='text-sm border text-center'>{JSON.parse(measurement)["waist"]}</p>


              <p className='text-sm border text-center'>Chest</p>
              <p className='text-sm border text-center'>{JSON.parse(measurement)["chest"]}</p>

              <p className='text-sm border text-center'>Hips</p>
              <p className='text-sm border text-center'>{JSON.parse(measurement)["hips"]}</p>
            </div>
            

          </dd>
        </div>
        )
    }


  return (
    <div>
        {
            !orderIsLoading && details.length > 0 && (
                <OrderDetails detailInfo={details} title={pageData.title} backLink={pageData.link} returnDate={!order.res.data.return_date && order.res.data.return_requested_at ? true : false} reloadPage={() => {
                  callOrder(path)
                  orderDetailsLoad()
                }}/>
            )
        }

        {/* {renderProduct()} */}
        {
            !orderIsLoading && order?.res.data.items.map((item, index) => renderProduct(item, index)) 
            
        }

        {/* detailInfo.map((info, index) => ( */}
    </div>

  )
}















// OrderView