import useDestroy from "hooks/useDestroy";
import React, { useEffect, useState } from "react";


class Option {
  constructor(id, label, value) {
    this.id = id
    this.label = label;
    this.value = value;
  }

  getLabel() {
    return this.label;
  }

  getValue() {
    return this.value;
  }

  setLabel(label) {
    this.label = label;
  }

  setValue(value) {
    this.value = value;
  }

  setId(id)   {
    this.id = id
  }
}

const FormWithAddButton = ({name , handleChange, oldAttribute , productId}) => {
  const  [destroy, apiDelete] = useDestroy()
  const [options, setOptions] = useState([]);
  const apiVersion = process.env.REACT_APP_API_VERSION;
  

  const handleAddButtonClick = () => {
    const newAttribute = new Option(null, "", "");
    setOptions([...options, newAttribute]);
  };
  const handleDelete = async(index , id) => {
    console.log(id, "delete");
    const updatedOptions = options.filter((_, i) => i !== index);
    if(id == null){
       setOptions(updatedOptions);
    }else{
       await apiDelete(apiVersion+'/admin/products/'+productId+'/attributes/'+oldAttribute?.id+'/option/'+id).then( ()=>{
         setOptions(updatedOptions);
       } )
      
    }
    handleChange({
      id: oldAttribute?.id ? oldAttribute?.id : null,
      name: name,
      options: updatedOptions,
    });
  };


  useEffect(() => {
    var oldOptions = [];
    if (oldAttribute) {
      
      oldAttribute?.options.map((attr, index) => {
       const newAttribute = new Option(attr.id ?? null ,attr.label, attr.value)
        oldOptions.push(newAttribute);
     })
      setOptions([...options, ...oldOptions])

      handleChange({
        "id": oldAttribute?.id ? oldAttribute?.id : null,
        "name" : name,
        "options": oldOptions
       });

    }
    

    
  }, [])
  
  const handleChangeLabel = (event, index) => {
    
    const updatedAttribute = options[index];
    updatedAttribute.setLabel(event.target.value);
    setOptions([...options]);
    handleChange({
      "name" : name,
      "options": options
    });
  };
   const handleChangeValue = (event, index) => {
    const updatedAttribute = options[index];
    updatedAttribute.setValue(event.target.value);
    setOptions([...options]);
    handleChange({
      "name" : name,
      "options": options
    });
  };

  return (
    <form className="flex flex-col gap-4 border border-gray-300 rounded-md">
      <div className="">

        <div className="border-b border-gray-300 p-2 mb-2">
          <p className="font-medium text-xl">{name} :</p>
        </div>

        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-4 mb-3 p-2">

            <div className="flex items-center gap-3 w-full">
              <label htmlFor={`option-${index}`} className="text-base font-medium whitespace-nowrap">Label :</label>
              <input 
              className="border border-gray-300 rounded-md w-full"
                type="text"
                name={`option-${index}`}
                value={option.label}
                onChange={(event) => handleChangeLabel(event, index)}
              />
            </div>

            {/* <div className="flex items-center gap-3 w-full">
              <label htmlFor={`option-value-${index}`} className="text-base font-medium whitespace-nowrap">Value :</label>
              <input
                className="border border-gray-300 rounded-md w-full"
                type="text"
                name={`option-value-${index}`}
                value={option.value}
                onChange={(event) => handleChangeValue(event, index)}
              />
            </div> */}

            <button className="border border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-4 py-2 rounded-md transition-none duration-200" type="button" onClick={()=>handleDelete(index , option.id )} >Delete</button>
            
          </div>
        ))}
      </div>

      <button className="border border-indigo-600 bg-indigo-600 text-white px-4 py-2 font-medium rounded-md w-auto mx-2 mb-2 hover:bg-white hover:text-indigo-600 transition-all duration-200" type="button" onClick={handleAddButtonClick}>
        Add Option
      </button>
       

    </form>
  );
};

export default FormWithAddButton;