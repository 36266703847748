import React, { FC , useRef } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { useSelector , useDispatch } from "react-redux";
import { registration } from "features/authentication/authSlice";
import { useEffect , useState } from "react";
import { useNavigate } from "react-router-dom";



import usePost from 'hooks/usePost';


const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const ForgetPassword = ({ className = "" }) => {
  
  
  
  
  const apiVersion = process.env.REACT_APP_API_VERSION;
  
  
  const [submitData, handlePost] = usePost("", {})
  const email = useRef(null);
  const [currentEmail, setCurrentEmail] = useState("")
  const userName = useRef(null);
  const password = useRef(null);
  const confirmPassword = useRef(null);

  const securityAns = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {authenticated, error} = useSelector((state) => state.auth)
  // const [message, setMessage] = useState("")
  const [passwordForm, setPasswordForm] = useState(false)
  const [securityQues, setSecurityQues] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
   
  const getSecurityQuestion = async ()=>{
    let body = {
      email: email.current.value
    }

    await handlePost(apiVersion+'/get-question', body).then((response) => {
      if (response.status == 200) {
        if (response.res.data.security_question) {

          setCurrentEmail(email.current.value)
          setSecurityQues(response.res.data.security_question)
          setPasswordForm(true)
          setErrorMsg("")
        }else{
          setErrorMsg(response.res.data.message)
        }

      }else{
        
        setErrorMsg(response.message)
      }
    })

  }



  const changePasswordHandler = async ()=>{
    
    let body = {
      email: currentEmail,
      password: password.current.value,
      password_confirmation: confirmPassword.current.value,
      security_question: securityQues,
      security_answer: securityAns.current.value
    }
    
    
    await handlePost(apiVersion+'/change-password', body).then((response) => {
      if (response.status == 200) {
        
        navigate('/login')
      }else{
        
        setErrorMsg(response.message ? response.message : "Password not updated")
      }
    })
  }



  const securityQuesForm = ()=>{

    return(
        <>
        {/* FORM */}
          <div className="grid grid-cols-1 gap-6">
            <span className="text-xs text-red-600 text-center">
                {errorMsg}
            </span>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                ref={email}
              />
            </label>




            <ButtonPrimary onClick={() => getSecurityQuestion()} >Continue</ButtonPrimary>
          </div>
        </>
    )
  }

  const newPasswordForm = () => {
      return (
          <>
         {/*pass FORM */}
          <div className="grid grid-cols-1 gap-6">
            <span className="text-xs text-red-600 text-center">
                {errorMsg}
            </span>
            <label>
              <span className="text-neutral-800 dark:text-neutral-200">
                {securityQues} ?
              </span>
              <Input
                type="text"
                placeholder="Racoon"
                className="mt-1"
                ref={securityAns}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                New Password
              </span>
              <Input type="password" className="mt-1" ref={password} />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password" className="mt-1" ref={confirmPassword} />
            </label>
            <ButtonPrimary onClick={() => changePasswordHandler()}>Continue</ButtonPrimary>
          </div>
          </>
      )
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Dainch</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forget Password
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          
          {
              !passwordForm ? securityQuesForm() : newPasswordForm()
          }


          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-green-600" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
