import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import usePost from 'hooks/usePost';


import { networkImage } from "utils/network";
import { useSelector, useDispatch } from 'react-redux'
import { setProduct, setSubTotal, getSubTotal, removeProduct, setQuantity, emptyCart } from "features/cart/cartSlice";



const CheckoutPage = () => {
  const apiVersion = process.env.REACT_APP_API_VERSION;
  const shippingCharge = process.env.REACT_APP_SHIPPINGCHARGE;

  const currency = process.env.CURRENCY;
  const [tabActive, setTabActive] = useState("ShippingAddress");
  const [inputValue, setInputValue] = useState({
    full_name: "",
    phone_no: "",
    address: "",
    shipping_charge: "",
    pin_code: "",
    city: "",
    state: ""
  })
   const navigate = useNavigate()
  const [submitData, handlePost] = usePost("", {})

  const dispatch = useDispatch();
  const {products, subTotal} = useSelector((state) => state.cart)
  const {user, token, authenticated, role, error} = useSelector((state) => state.auth)

  const handleScrollToEl = (id) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

// 127.0.0.1:8000/api/v1/customer/orders
  const path = apiVersion + "/customer/orders"; 
  
  const func = ( number, index) => {
    
    let prdInfo = {
      qty: number,
      productIndex: index
    }
    dispatch(setQuantity(prdInfo))
  };

  const placeOrder = async () => {

    let isEmptyValueExist = false

    for (let i = 0; i < Object.values(inputValue).length; i++) {
      
       if (Object.values(inputValue)[i] === '') {
         isEmptyValueExist = true

          break;
       }
    }

    if (!isEmptyValueExist) {
      let subtotalAfterShippingCharge = (subTotal * inputValue["shipping_charge"])/100

      let formData = {
        products: products,
        address: inputValue,
        delivery_charge: subtotalAfterShippingCharge,
      }

      // console.log(subTotal, inputValue["shipping_charge"])

      await handlePost(path, formData).then((response) => {
            
            if (response.status === 200) {
              dispatch(emptyCart())
              // emptyCart
              navigate('/customer/account-my-order/')
            }
            
          })
    }
    

    

  }

  const renderProduct = (product, index) => {
    const { image, price, name , variation, variationRaw} = product;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={variationRaw.image ? networkImage(variationRaw.image) : image }
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to="/product-detail" className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to="/product-detail">{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">


                    <span>{variation}</span>
                  </div>
                  
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber onChange={(number) => func(number, index)} defaultValue={product.quantity} className="relative z-10" />
            </div>

            <div
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm cursor-pointer "
              onClick={() => dispatch(removeProduct(index))}
            >
              <span>Remove</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const inputHandler = (data, field) => {
   
    let allInput = inputValue
    allInput[field] = data 
    
    setInputValue({...allInput})
  }


  

  const renderLeft = () => {
    return (
      <div className="space-y-8">

        <div id="ContactInfo" className="scroll-mt-24">
          {
            authenticated ?           
            <ContactInfo
            isActive={tabActive === "ContactInfo"}
            onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
            onCloseActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            info={user}
            /> 
            :
            <>
            </>
          }

        </div>

        <div id="ShippingAddress" className="scroll-mt-24">
          

          
          <ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onPlaceOrder={() => placeOrder()}
            onAddressChange={(data, field) => inputHandler(data, field)}
            allInputs={inputValue}
          />
        </div>

        {/* <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div> */}
      </div>
    );
  };

  useEffect(() => {
      if (products.length > 0) {
        dispatch(setSubTotal(products))
      }else{
        dispatch(setSubTotal([]))
      }
  }, [products])

  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>DaINCH</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Checkout
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={"/#"} className="">
              Clothing Categories
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Checkout</span>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold">Order summary</h3>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
              {products.map(renderProduct)}
            </div>

            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
              {/* <div>
                <Label className="text-sm">Discount code</Label>
                <div className="flex mt-1.5">
                  <Input sizeClass="h-10 px-4 py-3" className="flex-1" />
                  <button className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                    Apply
                  </button>
                </div>
              </div> */}

              <div className="mt-4 flex justify-between py-2.5">
                <span>Subtotal</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ₹{subTotal}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Shipping Charge</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  ₹{inputValue["shipping_charge"] !== "" ? (subTotal*inputValue["shipping_charge"]/100)  : 0}
                </span>
              </div>
              {/* <div className="flex justify-between py-2.5">
                <span>Tax estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $24.90
                </span>
              </div> */}
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                <span>Order total</span>
                <span>₹{inputValue["shipping_charge"] !== "" ? Math.ceil(subTotal+subTotal*inputValue["shipping_charge"]/100) : 0}</span>
              </div>
            </div>
            <ButtonPrimary onClick={() => placeOrder()} className="mt-8 w-full">
              Place Order
            </ButtonPrimary>
            <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
              <p className="block relative pl-5">
                <svg
                  className="w-4 h-4 absolute -left-1 top-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8V13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9945 16H12.0035"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Learn more{` `}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Taxes
                </a>
                <span>
                  {` `}and{` `}
                </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="##"
                  className="text-slate-900 dark:text-slate-200 underline font-medium"
                >
                  Shipping
                </a>
                {` `} infomation
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;







