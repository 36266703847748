import { createSlice } from '@reduxjs/toolkit'
import { networkImage } from 'utils/network'

export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
      shouldOpen: false,
      type:"",
      message:"",
      title:""
  },
  reducers: {
    open: (state) => {

        state.shouldOpen = true
    },
    close: (state) => {

        state.shouldOpen = false
    },
    insertToastData: (state, action) => {
        state.shouldOpen = true 
        state.type = action.payload.type 
        state.message = action.payload.message
        state.title = action.payload.title 
    }
    
  },
})

// Action creators are generated for each case reducer function
export const { 
     open,
     close,
     insertToastData
    } = toastSlice.actions

export default toastSlice.reducer





