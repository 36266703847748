import { PlusIcon } from '@heroicons/react/24/solid'
import FeaturesInput from 'components/FormInput/FeaturesInput'
import InputText from 'components/FormInput/InputText'
import ProductContainer from 'containers/ProductPage/ProductContainer'
import useIndex from 'hooks/useIndex'
import usePost from 'hooks/usePost'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function Feature() {
    const {productId} = useParams();    
    const [formInput, submitFeatures] = usePost('', {})
    const [productFeatures, featuresLoading, fetchFeatures] = useIndex('')
    const [oldFeatures, setOldFeatures] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const apiVersion = process.env.REACT_APP_API_VERSION;
    const path = apiVersion + "/admin/products/" + productId + "/features";
    

    let formData

    const pageData= {
      title: "Add Features"
    }
  const tabs = [
      { name: 'Edit Product', href: '/admin/products/edit/'+productId, current: false },
      { name: 'Image Upload', href: '/admin/products/edit/image/'+productId , current: false },
      { name: 'Attribute', href: '/admin/products/edit/attribute/'+productId, current: false },
      { name: 'Variation', href: '/admin/products/edit/variation/'+productId, current: false },
      { name: 'Features', href: '/admin/products/edit/feature/'+productId, current: true },
  ]
  const handleInputChange = (val) => {
    formData = val

  };

    const dataLoad = async () => {
        setLoading(true)
        await fetchFeatures(path).then(response => {
            
            if (response.res.data) {
                setOldFeatures(response.res.data)
            }
            setLoading(false)
        })
        .catch(error => {
            console.error('Error:', error);
            setLoading(false)
        });

    }
    
    useEffect(() => {
        dataLoad()
    }, [])


   const save = async () => {
     console.log('asdfsdf')
        // let features = formData.forEach(function(object) {
        //     delete object.id;
        // });
         

        submitFeatures(path, {"features":JSON.stringify(formData)}).then((response) => {
            navigate('/admin/products/')
        })
    }



  return (
    <ProductContainer title={pageData.title} tabData={tabs}>
          <div className="" >
            {
                !loading ? 
                <FeaturesInput class="w-full" name="Features"  handleChange={ handleInputChange} oldFeatures={oldFeatures} productId={productId}/>: <></>
            }
          </div>

            <button onClick={() => save()} className='bg-blue-700 mt-10 p-3 text-white'>Submit</button>

    </ProductContainer>
    
  )
}
