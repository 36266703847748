import { networkGet } from "utils/network";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'



const useDestroy = () => {

  const [destroy, setDestroy] = useState(null);
  const {token} = useSelector((state) => state.auth)


  function handleClick(api) {
    console.log(api)
    try{
       const response =    networkGet({
        method: 'DELETE',
        path:api,
        heads:{
            Authorization: `Bearer ${token}`,
            "Accept": "application/json"
        },
    })
   return response;
    }catch(error){
      throw error;
    }
    
  }

  return [destroy, handleClick];
};



export default useDestroy;