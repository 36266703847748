import { XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import FileUpload from 'components/FileUpload/FileUpload';
import ProductContainer from 'containers/ProductPage/ProductContainer';
import useDestroy from 'hooks/useDestroy';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import {Route, Link, Routes, useParams, useNavigate} from 'react-router-dom';
import { networkImage } from 'utils/network';

export default function VariationImage({selectedImage}) {
  const navigate = useNavigate()
  const {productId} = useParams();
  const [index, indexLoading, fetchImages] = useIndex('')
  const [submitData, handlePost] = usePost("", {})
  const [destroy, handleDelete] = useDestroy("")
  const [isDataExist, setIsDataExist] = useState(false)

  var thumbnail = '';
  var additionalImages = [];
  // const [thumbnail, setThumbnail] = useState('')
  // const [additionalImages, setAdditionalImages] = useState([])

  const [uploadedImages, setUploadedImages] = useState([])
  const [loading, setLoading] = useState(true)

  const apiVersion = process.env.REACT_APP_API_VERSION;
  const homePage = process.env.REACT_APP_HOME_PAGE;
  const path = apiVersion + "/admin/products/" + productId + "/images"; 



    const submitImages = async (exit) => {

      // console.log('form',thumbnail, additionalImages);
      console.log(exit)
      var formData = {
        'thumbnail' : thumbnail,
        'additional_images' : JSON.stringify(additionalImages)
      } ; 
      //set your logic here
      if (1==1) {
        await handlePost(path, formData).then((response) => {
          console.log(exit)
          if (response.status == 200) {

            if (exit) {
              
              navigate('/admin/products/')
            }
            else{
              navigate('/admin/products/edit/attribute/'+productId)
              
            }
            
            
          }
        })
        
      }else{
        alert('both needed')
      }


    }

    const getThumbnail = (fileList, field) => {
      console.log('getFileList',fileList[0], field)
      thumbnail = fileList[0];
    }
    const getAdditionalImages = (fileList, field) => {
      console.log('getFileList',fileList, field)
      additionalImages = fileList;
    }



  const dataLoad = async () => {
    setLoading(true)
    await fetchImages(apiVersion + "/admin/products/" + productId + "/image").then(response => {
      
      if (response.res.data.images.length > 0 || response.res.data.thumbnail_path) {
        setIsDataExist(true)
        let additionalImages = []
        response.res.data.images.map((image, index) => {
          additionalImages.push(image.path);
        })
        additionalImages.push(response.res.data.thumbnail_path);
        setUploadedImages(additionalImages)

        
        setLoading(false)
      }
    })
    .catch(error => {
        console.error('Error:', error);
    });

  }

  const selectImage = async (id, imgType) => {
      console.log(id, imgType);
  }


    useEffect(() => {
      dataLoad()
    }, [])
    

  return (

          <div className="grid grid-cols-4 gap-6" >
            <div className='col-span-4'>


              
              <div className='flex gap-2 py-5'>

              {
                !loading && uploadedImages.map((image, index) =>(
                  <div key={index} onClick={() => selectedImage(image)} className='border rounded-lg overflow-hidden relative group cursor-pointer'>
                    
                    <img className='h-32 w-32' src={networkImage(image)} alt="" />
                  </div>
                ))
              }
              

                
              </div>
            </div>
            

          </div>

    
  )
}
