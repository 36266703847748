// Dropdown.jsx
import React, { useEffect, useState } from 'react';

const Dropdown = ({ options, oldValue, onOptionChange, title, error }) => {

  
  const handleChange = (e) => {
    const selectedValue = e.target.value;

    options.map(option => {
      if (option.value === e.target.value) {
        onOptionChange(option.key, e.target.value);        
      }
    })
  };
  useEffect(() => {
    if (oldValue !== "") {
      const selectedKey = oldValue;

      options.map(option => {
      if (option.key === selectedKey) {
      



        onOptionChange(option.key, option.value);
      }
      })
    }
  }, [oldValue])
  

  return (
    <div className='border border-black rounded-lg px-2 py-2 text-xs'>
    <label htmlFor={title} >
      <p className={error.length > 0 ? 
        'text-red-500 block text-sm group-focus-within:text-slate-900 focus-within:ring-slate-900' 
        : 
        'text-slate-700 block text-sm group-focus-within:text-slate-900 focus-within:ring-slate-900'
      }>{title}</p>
      <select id={title} label={title} className='border-0 w-full focus:ring-0 text-xs bg-slate-200' onChange={handleChange}>
        
        {options.map((option) => (
          <option key={option.key} value={option.value}   selected={option.key == oldValue ? true :false}>
            {option.value}
          </option>
        ))}
      </select>
    </label>
        <span
      className="text-xs font-light text-red-500 absolute left-0 -bottom-5"
      >{error}</span> 
      </div>
  );
}

export default Dropdown;
