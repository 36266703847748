/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authService from './authService';

const initialState = {
    
    user: null,
    authenticated:  false,
    token:  null,
    role:  null,
    error: null,
}



// user login
export const userLogin = createAsyncThunk('auth/userLogin', async (data, thunkAPI) => {

    try {
        return authService.userLogin(data)

    } catch (error) {
        console.log('here in error',error);
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

// get user
export const getUser = createAsyncThunk('auth/getUser', async (data, thunkAPI) => {

    try {

        return authService.getUser(true, data, "customer")
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})

// logout
export const logout = createAsyncThunk('auth/logout', async (data,thunkAPI) => {
    try {
        return authService.logout(data)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})
export const registration = createAsyncThunk('auth/registration', async (data,thunkAPI) => {
    try {
        return authService.registration(data)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }
})




export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.user = null
            state.authenticated = null
            state.token = null
            state.role = null
            state.error = null
        },
        clearError: (state) => {
            
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.isLoading = true
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.isLoading = false
                state.token = action.payload.access_token
                state.user = action.payload.user
                state.role = action.payload.user.roles[0].name
                state.authenticated = true
                
            })
            .addCase(userLogin.rejected , (state, action) => {
                state.isLoading = false
                state.authenticated = false
                
                state.error = JSON.parse(action.error.message)
                // state.error = action.error.message
            })
            .addCase(registration.pending, (state) => {
                state.isLoading = true
            })
            .addCase(registration.fulfilled, (state, action) => {
                state.isLoading = false
                state.token = action.payload.access_token
                state.user = action.payload.user
                state.role = action.payload.user.roles[0].name
                state.authenticated = true
                
            })
            .addCase(registration.rejected , (state, action) => {
                state.isLoading = false
                state.authenticated = false
                
                state.error = JSON.parse(action.error.message)
                // state.error = action.error.message
            })
            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false
                    console.log(action.payload, 'asdfsdf')
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false
                state.user = action.payload
            })
            .addCase(logout.pending, (state) => {
                state.isLoading = true
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoading = false
                state.token = null
                state.user = null
                state.role = null
                state.authenticated = false
            })
            .addCase(logout.rejected, (state, action) => {
                state.isLoading = false
                state.user = action.payload
            })
    }
})

export const { reset, clearError } = authSlice.actions;
export default authSlice.reducer;