import Details from 'components/Details/Details';
import useIndex from 'hooks/useIndex';
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

export default function View() {
    const { productId } = useParams();
    const apiVersion = process.env.REACT_APP_API_VERSION;
    const pageData = {
        title: "Product Details",
        link:   "/admin/products/",
    }
    const [details, setDetails] = useState([])

    let path = apiVersion + "/admin/products/"+productId;

    const [product, productsIsLoading, callProduct] = useIndex(path)

    useEffect(() => {
        if (!product) {
            callProduct(path).then((response) => {
                console.log(response.res.data)
            })
        }else{





            setDetails([
                {'key': "Image" , 'value' : product.res.data.thumbnail_path , 'type' : 'image' },
                {'key': "Category Name" , 'value' : product.res.data.name , 'type' : 'text' },
                {'key': "Parent" , 'value' : product.res.data.parent ? product.res.data.parent : "No Parent" , 'type' : 'text' },
                {'key': "Price" , 'value' : product.res.data.price , 'type' : 'text' },
                {'key': "Sku" , 'value' : product.res.data.sku , 'type' : 'text' },
                {'key': "Slug" , 'value' : product.res.data.slug , 'type' : 'text' },
                {'key': "status" , 'value' : product.res.data.status , 'type' : 'text' },
                {'key': "unit" , 'value' : product.res.data.unit , 'type' : 'text' },
            ])
        }


    }, [productsIsLoading])




  return (
    <div>
        {
        !productsIsLoading && details.length > 0 && (
            <Details detailInfo={details} title="Product Details" backLink={pageData.link}/>
        )
    }
    </div>

  )
}
