import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const MEGAMENU_TEMPLATES: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Page",
    children: [
      { id: ncNanoId(), href: "/", name: "Home  1" },
      { id: ncNanoId(), href: "/home2", name: "Home  2", isNew: true },
      { id: ncNanoId(), href: "/", name: "Header  1" },
      { id: ncNanoId(), href: "/home2", name: "Header  2", isNew: true },
      { id: ncNanoId(), href: "/", name: "Coming Soon" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Shop Pages",
    children: [
      { id: ncNanoId(), href: "/page-collection", name: "Category Page 1" },
      { id: ncNanoId(), href: "/page-collection", name: "Category Page 2" },
      { id: ncNanoId(), href: "/product-detail", name: "Product Page 1" },
      { id: ncNanoId(), href: "/product-detail-2", name: "Product Page 2" },
      { id: ncNanoId(), href: "/cart", name: "Cart Page" },
      { id: ncNanoId(), href: "/checkout", name: "Checkout Page" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Other Pages",
    children: [
      { id: ncNanoId(), href: "/checkout", name: "Checkout Page" },
      { id: ncNanoId(), href: "/page-collection", name: "Search Page" },
      { id: ncNanoId(), href: "/cart", name: "Cart Page" },
      { id: ncNanoId(), href: "/account", name: "Accout Page" },
      { id: ncNanoId(), href: "/account-my-order", name: "Order Page" },
      { id: ncNanoId(), href: "/subscription", name: "Subscription" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Blog Page",
    children: [
      { id: ncNanoId(), href: "/blog", name: "Blog Page" },
      { id: ncNanoId(), href: "/blog-single", name: "Blog Single" },
      { id: ncNanoId(), href: "/about", name: "About Page" },
      { id: ncNanoId(), href: "/contact", name: "Contact Page" },
      { id: ncNanoId(), href: "/login", name: "Login" },
      { id: ncNanoId(), href: "/signup", name: "Signup" },
    ],
  },
];

const OTHER_PAGE_CHILD: NavItemType[] = [
  
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Kurties",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Anarkali Kurti",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "A-Line Kurti",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Kaftan Kurti",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Long Kurti",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Jacket Style Kurti",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Flared Kurti",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Overlay Kurti",
      },
    ],
  },
];

const OTHER_PAGE_GIRL: NavItemType[] = [

   {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Printed Kurti",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Crop Top Style Kurti",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Angrakha Kurti",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Asymmertic Hem Kurti",
  },
 
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Embroidered kurti",
  },
];
const OTHER_PAGE_STATE: NavItemType[] = [

  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Rajasthani",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Jaipuri",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Jhodhpuri",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Sanganeri",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Bagru",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Block Print",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Bandhani",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Punjabi",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Phulkari",
      },
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Thread Karigari",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Lucknow",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Chiknkri",
      },
    ],
  },
  


  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Hyderabdi",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Zardozi Embroidery",
      },
    ],
  },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "State Essence",
    type: "megaMenu",
    children: OTHER_PAGE_STATE,
  },
  
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Women",
    type: "dropdown",
    children: OTHER_PAGE_CHILD,
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Girls",
    type: "dropdown",
    children: OTHER_PAGE_GIRL,
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Suits for Every Mood",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Get Your Fitting",
  },

  
];

