import React, { useState , useEffect } from 'react';
import ReactDOM from 'react-dom';

// Import React FilePond
import { FilePond , registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Our app
export default function FileUpload({fileListFunction, title, multiple}) {
     const [files, setFiles] = useState([]);
     const baseURL = process.env.REACT_APP_API_ENDPOINT;
     const apiVersion = process.env.REACT_APP_API_VERSION;
     const path = baseURL+ apiVersion + "/upload/image"; 
     const onProcessFile = (file) => {
        var uploadedFileList = [];
        console.log(files)

        for (let i = 0; i < files.length; i++) {
            uploadedFileList.push(files[i].serverId);
        }
        // files.map((uploadedFile)=>{
        //     uploadedFileList.push(uploadedFile.serverId);
        // })
                console.log(uploadedFileList)
        fileListFunction(uploadedFileList, title);
        
        };
     const onUpdateFiles = (file) => {
         console.log(file)
        
        setFiles(file)
         console.log(files)
    }
     

    useEffect(() => {

         if (files.length > 0) {
         console.log(files, 'asdofmawe')
             onProcessFile('something')
         }
}, [files]);
    return (
        <div className="App">
            <p className='text-base pb-2'>Upload {title}</p>
            <FilePond
                files={files}
                onprocessfile={onProcessFile}
                onupdatefiles={onUpdateFiles}
                allowMultiple={multiple}
                server={path}
                name="image"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
        

        </div>
    );
}
