import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import useGet from 'hooks/useGet';
import useIndex from 'hooks/useIndex';
import Table from   'components/Table/Table'
import useNetworkImage from 'hooks/useNetworkImage';
import { networkImage } from 'utils/network';
import ActionCell from 'components/Table/ActionCell';
import Pagination from 'components/Pagination/Pagination';
import usePost from 'hooks/usePost';
import Filter from 'components/FormInput/Filter';

export default function ProductCreation() {
    const apiVersion = process.env.REACT_APP_API_VERSION;
        const [submitData, handlePost] = usePost("", {}) 

    const pageData = {
        title: "products",
        link:   "/admin/products/create"
    }
    const crudLink = "/admin/products";
    // let path = apiVersion + "/admin/products";
    const [path, setPath] = useState(apiVersion + "/admin/products")

    const [products, productsIsLoading, callProducts] = useIndex('')
    const [totalPages, setTotalPages] = useState(0)    
    
   
    let tableHeader = [
      { field: "image_path", title: "Image", sortable: false, arrange: true },
      { field: "name", title: "Name", sortable: true, arrange: true },
      { field: "Variation", title: "Variation", sortable: false, arrange: false },
      { field: "price", title: "Price", sortable: false, arrange: false },
      { field: "quantity", title: "Quantity", sortable: false, arrange: false },
      { field: "featured", title: "featured", sortable: false, arrange: false },
      { field: "status", title: "Status", sortable: false, arrange: false },
      { field: "created_at", title: "Date", sortable: false, arrange: false },
    ]


    let tableHeader2 = [
      { field: "featured", title: "featured", sortable: false, arrange: true },
      { field: "name", title: "Name", sortable: true, arrange: true },
    ]


    useEffect(() => {
      dataLoad(path)
    }, [])


    const dataLoad = (api) => {
      callProducts(api).then((response) => {
        setTotalPages(response.res.data.last_page)
        console.log(response.res.data.data)
      })
    }

    const pageRefresh = () => {
      callProducts(path)
    }

    const changeStatus = (e, id ) => {
      console.log(e.target.value, id)
      let publishApi = apiVersion+'/admin/products/'+id+'/publish'
      handlePost(publishApi, {"status": e.target.value}).then((response) => {
        dataLoad(path)
      })
    }

    const changeFeatured = (e, id ) => {
      let featuredApi = apiVersion+'/admin/products/'+id+'/featured'
      handlePost(featuredApi, {"featured": e.target.value}).then((response) => {
        dataLoad(path)
      })
    }


    const filter = (dt) => {
      
        if (dt.search === "") {
          dataLoad(path);
        } else {
          let searchInput = dt.search;
          let field = dt.fieldName;
          console.log(searchInput, field)
          let filterApi = path + "?filter[" + field + "]=" + searchInput;
          if (dt.fieldName) {
            dataLoad(filterApi)
          }
      }
    }
  return (
    <div className=''>
        <div className='flex justify-between items-end flex-wrap pb-5 pl-5 gap-2'>
            <Filter data={tableHeader2} label={'All Products'} updateInput={(search) => filter(search)} resetPage={() => dataLoad(path)}/>
           <Link className='bg-green-700 hover:bg-green-300 hover:text-green-700 text-xs p-2 rounded-lg text-white' to={pageData.link}>Add New Product</Link>
 
        </div>

        <div>
          <Table headers={tableHeader} title="Products">
                <tbody className="divide-y divide-gray-200 bg-white">


                  {!productsIsLoading && products?.res.data.data.map((product, index) => (
                    <tr key={index} >
                      <ActionCell 
                        rowId={product['id']} 
                        viewLink={"/product-detail" + '/' + product['slug']}
                        editLink={crudLink + '/edit/' + product['id']}
                        deleteLink={path+'/'+product['id']}
                        refresh={pageRefresh} 
                      />
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex justify-center">
                        
                        <div className="w-20 h-20">
                          <img
                          src={networkImage(product["thumbnail_path"])}
                          className="w-full h-full object-fit flex-shrink-0 border p-1 border-darkGray/40 rounded-md"
                          />
                        </div>
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { product["name"].substring(0, 20)}
                      </td>

                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { product["has_variation"] ? "YES" : "NO"}
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { 
                          product["price"]
                        }
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { 
                          product["quantity"]
                        }
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                          <div className='flex  items-center justify-center h-20'>
                            <select onChange={(event) => changeFeatured(event, product.id)} name="featured" id="featured">
                              <option selected={product["featured"] ? false : true} >Seletec One</option>
                              <option  
                              selected={product["featured"] =="Trending" ? true : false} value="Trending">Trending</option>
                              <option selected={product["featured"] =="Best_selling" ? true : false} value="Best_selling">Best Selling</option>
                              <option selected={product["featured"] =="popular" ? true : false}  value="popular">Popular</option>
                              <option selected={product["featured"] =="Trending_BestSelling" ? true : false}  value="Trending_BestSelling">Trending & Best Selling</option>
                              <option selected={product["featured"] =="Trending_Popular" ? true : false}  value="Trending_Popular">Trending & Popular</option>
                              <option selected={product["featured"] =="BestSelling_Popular" ? true : false}  value="BestSelling_Popular">Best Selling & Popular</option>
                              
                            </select>
                          </div>

                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                          <div className='flex  items-center justify-center h-20'>
                            <select onChange={(event) => changeStatus(event, product.id)} name="status" id="status">
                              <option selected={product["status"] == "Draft" ? true: false} value="Draft">Draft</option>
                              <option 
                              selected={product["status"] == "Inactive" ? true: false} value="Inactive"
                              >
                                Inactive
                              </option>
                              <option selected={product["status"] == "Active" ? true: false} value="Active">Active</option>
                            </select>
                          </div>

                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { 
                          product["created_at"].substring(0, 10)
                        }
                      </td>
                    </tr>
                  ))}
                  
                </tbody>
          </Table>
          <div className='flex justify-center pt-8'>

            <Pagination pageRoot="/admin/products/page/" totalPages={totalPages}/> 

          </div>
        </div>
    </div>
  )
}
