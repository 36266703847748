import React, { useState } from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import {  useEffect } from 'react';
import useIndex from 'hooks/useIndex';
import SectionSliderProductCard2 from "components/SectionSliderProductCard2";
import useGet from "hooks/useGet";


function PageHome() {
    const [products, productsIsLoading, callProducts] = useGet('')
    
    const [allProducts, setAllProducts] = useState()
    const [trending, setTrending] = useState()
    const [bestSelling, setBestSelling ] = useState()
    const [popular, setPopular] = useState()
    const [trendNBestSell, setTrendNBestSell] = useState()
    const [trendPopular, setTrendPopular] = useState()
    const [bestPopular, setBestPopular] = useState()
    
    const apiVersion = process.env.REACT_APP_API_VERSION;
    
    let path = apiVersion+'/products'
    // const filters =["All items", "Women", "Mans", "Kids", "jewels"]
    const [filters, setFilters] = useState(["All items", "Women", "Mans", "Kids", "jewels"])
    
    useEffect(() => {

      loadAllProducts()
      loadTrandingProducts()
      loadBestSellingProducts()
      loadPopularProducts()
      loadTrendingNBestSell()
      loadTrendingNPopular()
      loadBestPopular()
    }, [productsIsLoading])


    const loadAllProducts = () => {
        callProducts(path).then((response) => {
          // console.log(response.res.data.data)
          setAllProducts(response.res.data.data)
        })
    }


    const loadTrandingProducts = () => {
      let trendingApi = apiVersion+'/featured-products?filter[featured]=Trending'
        callProducts(trendingApi).then((response) => {
          // console.log(response.res.data)
          setTrending(response.res.data)
        })

    }

      // let trendingNBestSellApi = apiVersion+'/featured-products?filter[featured]=Trending & Best Selling'
    
    const loadBestSellingProducts = () => {
      let trendingApi = apiVersion+'/featured-products?filter[featured]=Best_selling'
        callProducts(trendingApi).then((response) => {
          // console.log(response.res.data)
          setBestSelling(response.res.data)
        })

    }
    

    const loadPopularProducts = () => {
      let popularApi = apiVersion+'/featured-products?filter[featured]=Popular'
        callProducts(popularApi).then((response) => {
          // console.log(response.res.data)
          setPopular(response.res.data)
        })

    }

    const loadTrendingNBestSell = () => {
        let trendNBestSellApi = apiVersion+'/featured-products?filter[featured]=Trending_BestSelling'
        callProducts(trendNBestSellApi).then((response) => {
          setTrendNBestSell(response.res.data)
        })
    }


    const loadTrendingNPopular = () => {
        let trendingNPopularApi = apiVersion+'/featured-products?filter[featured]=Trending_Popular'
        callProducts(trendingNPopularApi).then((response) => {
          setTrendPopular(response.res.data)
        })
    }


    const loadBestPopular = () => {
        let bestPopularApi = apiVersion+'/featured-products?filter[featured]=BestSelling_Popular'
        callProducts(bestPopularApi).then((response) => {
          
          setBestPopular(response.res.data)
        })
    }
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      <SectionHero2 />

      <div className="mt-24 lg:mt-32">
        <DiscoverMoreSlider />
      </div>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* new arrivals */}

        

        
        {
          allProducts?.length > 0 ? <SectionSliderProductCard data={allProducts} heading="Latest" subHeading="Products"  /> : <></>
        }
        {
          trending?.length > 0   ? <SectionSliderProductCard data={trending} heading="Trending" subHeading=" Products"  /> : <></>
        }

        {
          bestSelling?.length > 0 ? <SectionSliderProductCard data={bestSelling} heading="Best Selling" subHeading=" Products"  /> : <></>
        }
        {
          popular?.length > 0 ? <SectionSliderProductCard data={popular} heading="Popular" subHeading=" Products"  /> : <></>
        }
        {
          trendNBestSell?.length > 0 ? <SectionSliderProductCard data={trendNBestSell} heading="Trending + Best Sell" subHeading=" Products"  /> : <></>
        }
        {
          trendPopular?.length > 0 ? <SectionSliderProductCard data={trendPopular} heading="Trending + Popular" subHeading=" Products"  /> : <></>
        }
        
        {
          bestPopular?.length > 0 ? <SectionSliderProductCard data={bestPopular} heading="Best + Popular" subHeading=" Products"  /> : <></>
        }

        <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
          <SectionHowItWork />
        </div>

        {/* SECTION */}
        <SectionPromo1 />

        {/* SECTION */}
        <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div>

        {/* SECTION */}
        <SectionGridFeatureItems  filters ={filters} />

        {/*  */}
        <SectionPromo2 />

        {/* SECTION 3 */}
        <SectionSliderLargeProduct cardStyle="style2" />

        {/*  */}
        <SectionSliderCategories />

        {/* SECTION */}
        <SectionPromo3 />

        <SectionSliderProductCard
          heading="Best Sellers"
          subHeading="Best selling of the month"
        />

        <div className="relative py-24 lg:py-32">
          <BackgroundSection />
          <div>
            <Heading rightDescText="From the DaINCH Board">
              The latest news
            </Heading>
            <SectionMagazine5 />
            <div className="flex mt-16 justify-center">
              <ButtonSecondary>Show all blog articles</ButtonSecondary>
            </div>
          </div>
        </div>

        {/*  */}
        <SectionClientSay />
      </div>
    </div>
  );
}

export default PageHome;
