import React, { useRef, useState } from 'react'
import Input from 'shared/Input/Input'

const InputText = ({title, label, type, error, oldValue, onInputChange}) => {
  const [errorMsg, setErrorMsg] = useState("")
  const inputRef = useRef(null);

  const sendInput = (e) => {
    onInputChange(inputRef.current.value, label)
  }


  return (
  <main
    className={
      error.length > 0 ? 
      'ring-red-500 rounded-md px-3 pt-2.5 bg-white pb-1.5 w-full shadow-sm ring-1 ring-inset focus-within:ring-1 focus-within:ring-primary-col group relative' 
    : 
    'ring-gray-600 rounded-md px-3 pt-2.5 bg-white pb-1.5 w-full shadow-sm ring-1 ring-inset focus-within:ring-1 focus-within:ring-primary-col group relative'}
  >
    <label
      htmlFor={label}
      className={error.length > 0 ? 
        'text-red-500 block text-sm group-focus-within:text-slate-900 focus-within:ring-slate-900' 
        : 
        'text-slate-700 block text-sm group-focus-within:text-slate-900 focus-within:ring-slate-900'
      }
      >{title}</label>
    <input
      onChange={sendInput}
      type={type}
      name={label}
      id={label}
      className="block w-full border-0 p-0 text-gray-900 bg-transparent text-base placeholder:text-gray-400 focus:ring-0 focus:outline-none sm:text-sm sm:leading-6"
      ref={inputRef}
      defaultValue={oldValue}
    />
    <span
      className="text-sm font-light text-red-500 absolute left-0 -bottom-5"
      >{error}</span> 
  </main>
  )
}

export default InputText