import React, { useEffect, useRef, useState } from 'react'
import Input from 'shared/Input/Input'
import Dropdown from 'components/FormInput/Dropdown'
import useGet from 'hooks/useGet';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import useShow from 'hooks/useShow';
import useDestroy from 'hooks/useDestroy';
import InputText from 'components/FormInput/InputText';
import MultipleSelect from 'components/FormInput/MultipleSelect';
import { useNavigate, useParams } from 'react-router-dom';
import ProductContainer from 'containers/ProductPage/ProductContainer';


export default function EditDeliveryCharge() {

    const apiVersion = process.env.REACT_APP_API_VERSION;
    const backendUrl = process.env.REACT_APP_API_ENDPOINT;
    const {chargeId} = useParams();
    const [submitData, handleClick] = usePost("", {})
    const [index, indexLoading, fetchDeliveryCharge] = useIndex('')

    const navigate = useNavigate()
    let path = apiVersion+'/admin/delivery-charge-setting'
    const pageData= {
      title: "Edit Delivery Charge"
    }

    // const inputs ={
    //   name:null,
    //   description:null,

    // }

    const [inputs, setInputs] = useState({
      _method: "PUT",
      state:null,
      charge:null,

    })


  const tabs = [
    { name: 'Edit Product', href: '/admin/products/edit/'+chargeId, current: true },
    { name: 'Image Upload', href: '/admin/products/edit/image/'+chargeId , current: false },
    { name: 'Attribute', href: '/admin/products/edit/attribute/'+chargeId, current: false },
    { name: 'Variation', href: '/admin/products/edit/variation/'+chargeId, current: false },
    { name: 'Features', href: '/admin/products/edit/feature/'+chargeId, current: false },
  ]




  var brandOptions = [{ key: 1, value: 'good' }];

  const handleInputChange = (value, label) => {
    inputs[label] = value
    setInputs({...inputs})
  };





  const submit =  async () => {
    // console.log(inputs)
    await handleClick(path+'/'+chargeId, inputs).then((response) => {
      if (response.status == 200) {
        navigate('/admin/delivery-charges')
        
      }
    })


  };


  
  const [loading, setLoading] = useState(null)

    const dataLoad = async () => {
      setLoading(true)
      await fetchDeliveryCharge(path+'/'+chargeId).then(response => {
          setLoading(false)
        if(response){
          inputs.state = response.res.data.state 
          inputs.charge = response.res.data.charge
          setInputs({...inputs})
        }
        console.log(inputs)
      })
      .catch(error => {
          console.error('Error:', error);
      });

    }

    useEffect(() => {
      if (loading == null) {
        // console.log('asdfsdf')
        dataLoad()
      }
    }, [])
  

  return (
    <ProductContainer title={pageData.title} tabData="">
          <div >
            {
              !loading && (
                <div className="grid grid-cols-4 gap-6" >
                  <InputText onInputChange={handleInputChange} title="State" label="state" type="text" error="" oldValue={inputs.state ? inputs.state: ""} />


                  <InputText onInputChange={handleInputChange} title="Delivery charge" label="charge" type="number" error="" oldValue={inputs.charge ? inputs.charge : "" } />
                  
                </div> 
               )
            } 

          </div>

          <div className='flex gap-2'>
            <button  onClick={() => submit()} className='p-2 bg-blue-400 hover:bg-blue-700 transition-all duration-150 text-white rounded-lg mt-6 px-5 uppercase font-medium shadow-md'>Save</button>
            
          </div>

    </ProductContainer>
  )
}
