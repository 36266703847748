import React, { useEffect, useRef, useState } from 'react'
import Input from 'shared/Input/Input'
import Dropdown from 'components/FormInput/Dropdown'
import useGet from 'hooks/useGet';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import useShow from 'hooks/useShow';
import useDestroy from 'hooks/useDestroy';
import InputText from 'components/FormInput/InputText';
import MultipleSelect from 'components/FormInput/MultipleSelect';
import { useNavigate } from 'react-router-dom';
import ProductContainer from 'containers/ProductPage/ProductContainer';


export default function CreateDeliveryCharge() {

  const apiVersion = process.env.REACT_APP_API_VERSION;
  const navigate = useNavigate()
  const pageData= {
    title: "Create Delivery Charge"
  }

  const inputs ={
    state:null,
    charge:null,

  }

//   state
// XZY

// charge

  const [deliveryInput, setDeliveryInput]  = useState({
    state:"99",
    charge:null,
  })

  const [submitData, handlePostReq] = usePost("", inputs)



  const handleInputChange = (value, label) => {
    inputs[label] = value
  };

// admin/delivery-charge-setting

  const submit =  async () => {
    // handlePostReq(inputs)
console.log(inputs)
     await handlePostReq(apiVersion+'/admin/delivery-charge-setting', inputs).then((response) => {
          if (response.status == 200) {
            
            navigate('/admin/delivery-charges')
            // console.log(response)
          }
        })
  };

  


  return (
    <ProductContainer title={pageData.title} tabData="">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6" >
            <InputText onInputChange={handleInputChange} title="State" label="state" type="text" error="" oldValue="" />



            {/* <Dropdown 
            title="Choose a State"
              options={[
                {key: 2, value: 'dinajpur'},
                {key: 3, value: 'dhaka'},
                ]}
                oldValue=""
                error=""
                onOptionChange={(key, value) => {
                  setDeliveryInput((prevState) => {
                    // prevState["state"] = value
                    console.log(prevState)
                  })
                  // console.log(deliveryInput)
                  console.log(key, value)
                } }
              /> */}



            <InputText onInputChange={handleInputChange} title="Delivery charge" label="charge" type="number" error="" oldValue="" />
          </div>

          <button  onClick={() => submit()} className='p-2 bg-blue-400 hover:bg-blue-700 transition-all duration-150 text-white rounded-lg mt-6 px-5 uppercase font-medium shadow-md'>Save</button>

    </ProductContainer>
  )
}
