import { XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import FileUpload from 'components/FileUpload/FileUpload';
import ProductContainer from 'containers/ProductPage/ProductContainer';
import useDestroy from 'hooks/useDestroy';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import {Route, Link, Routes, useParams, useNavigate} from 'react-router-dom';
import { networkImage } from 'utils/network';




// filepond part 
// Import React FilePond
import { FilePond , registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
// filepond parts 

export default function Image() {
  const navigate = useNavigate()
  const {productId} = useParams();
  const [index, indexLoading, fetchImages] = useIndex('')
  const [temporaryImages, temporaryImagesLoading, fetchTemporaryImages] = useIndex('')
  const [destroyTempImage, destroyTempImageLoading, fetchDestroyTempImage] = useIndex('')
  const [submitData, handlePost] = usePost("", {})
  const [destroy, handleDelete] = useDestroy("")
  const [isDataExist, setIsDataExist] = useState(false)

  var [thumbnail, setThumbnail] = useState('');
  var [additionalImages ,setAdditionalImages] = useState([]);
  // const [thumbnail, setThumbnail] = useState('')
  // const [additionalImages, setAdditionalImages] = useState([])

  const [uploadedImages, setUploadedImages] = useState([])
  const [loading, setLoading] = useState(true)


  const apiVersion = process.env.REACT_APP_API_VERSION;
  const homePage = process.env.REACT_APP_HOME_PAGE;
  const path = apiVersion + "/admin/products/" + productId + "/images"; 


// filepond codes
    const [files, setFiles] = useState([]);
    const [additionalFiles, setAdditionalFiles] = useState([]);
     const baseURL = process.env.REACT_APP_API_ENDPOINT;
     const imagePath = baseURL+ apiVersion + "/upload/image"; 

     const onProcessFile = async (file) => {
        var uploadedFileList = [];
        console.log(files)

        


        for (let i = 0; i < files.length; i++) {
            uploadedFileList.push(files[i].serverId);
        }
        // files.map((uploadedFile)=>{
        //     uploadedFileList.push(uploadedFile.serverId);
        // })
                console.log(uploadedFileList)
          getThumbnail(uploadedFileList, 'thumbnail');
        
        };


    const onProcessAdditionalFile = async (file) => {
        var uploadedFileList = [];
        // console.log(additionalFiles)


        await fetchTemporaryImages(apiVersion + "/temporary-files").then(response => {
          // console.log(response.res.data)
          setAllImages(response.res.data)
          
        })
        // for (let i = 0; i < additionalFiles.length; i++) {
        //     uploadedFileList.push(additionalFiles[i].serverId);
        // }
       
        //   getAdditionalImages(uploadedFileList, 'additional_images');
        
        };

        
     const onUpdateFiles = (file) => {
         console.log(file)
        
        setFiles(file)
         console.log(files)
    }

    const onUpdateAdditionalFiles = (file) => {

      setAdditionalFiles(file)
      
        console.log(files)
    }
     

//     useEffect(() => {

//          if (files.length > 0) {
//          console.log(files, 'asdofmawe')
//              onProcessFile('something')
//          }
// }, [files]);

// filepond codes



    const pageData= {
      title: "Upload Image"
    }
  const tabs = [
    { name: 'Edit Product', href: '/admin/products/edit/'+productId, current: false },
    { name: 'Image Upload', href: '/admin/products/edit/image/'+productId , current: true },
    { name: 'Attribute', href: '/admin/products/edit/attribute/'+productId, current: false },
    { name: 'Variation', href: '/admin/products/edit/variation/'+productId, current: false },
    { name: 'Features', href: '/admin/products/edit/feature/'+productId, current: false },
  ]

    const submitImages = async (exit) => {

      console.log('form',thumbnail, additionalImages);
      // console.log(exit)
      var formData = {
        'thumbnail' : thumbnail,
        'additional_images' : JSON.stringify(additionalImages)
      } ; 
      
      //set your logic here
        await handlePost(path, formData).then((response) => {
          console.log(exit)
          if (response.status == 200) {

            if (exit) {
              
              navigate('/admin/products/')
            }
            else{
              navigate('/admin/products/edit/attribute/'+productId)
              
            }
            
            
          }
        })
    

    }

    const getThumbnail = (fileList, field) => {
      console.log('getFileList',fileList[0], field)
      thumbnail = fileList[0];
    }
    const getAdditionalImages = (fileList, field) => {
      console.log('getFileList',fileList, field)
      additionalImages = fileList;
    }



  const dataLoad = async () => {
    setLoading(true)
    await fetchImages(apiVersion + "/admin/products/" + productId + "/image").then(response => {
      
      if (response.res.data.images.length > 0 || response.res.data.thumbnail_path) {
        console.log(response.res.data.images)
        setIsDataExist(true)
        setUploadedImages(response.res.data)
        setLoading(false)
      }
    })
    .catch(error => {
        console.error('Error:', error);
    });

  }

  const deleteImage = async (id, imgType) => {
    console.log(id, imgType);
    if (imgType == 'thumbnail') {
      await handleDelete(apiVersion + "/admin/products/" + productId + "/delete-thumbnail").then( ()=>{
        dataLoad()
      })
    }else{
      await handleDelete(path+"/"+id).then( ()=>{
        dataLoad()
      })
    }

  }


    useEffect(() => {
      dataLoad()

      temopraryAllTemporaryImages()
    }, [])

    const [allImages, setAllImages] = useState([])
    const temopraryAllTemporaryImages = async() => {

        await fetchTemporaryImages(apiVersion + "/temporary-files").then(response => {
          // console.log(response.res.data)
          setAllImages(response.res.data)
        })
    }
    
    const destroyTemporaryImage = (folder) =>{
      fetchDestroyTempImage(apiVersion + "/delete-temporary-files/"+folder).then(response => {
        setAllImages(response.res.data);
      });
    }

    const imageSelection = (image) => {
      setThumbnail(image);
      let images = [];
      allImages.map((img)=>{
        // console.log(img);
          if(img.folder != image){
            images.push(img.folder);
          }
      });
      setAdditionalImages(images);
      console.log(thumbnail);
      console.log(additionalImages);
    
    }

  return (
    <ProductContainer title="Upload Images" tabData={tabs}>
          <div className="grid grid-cols-4 gap-6" >
            <div className='col-span-4'>



              <div className='flex items-center gap-5'>
                <p className='text-sm'>Thumbnail Image</p>
                
              </div>
              <div className='flex gap-2 py-5'>

              {
                !loading &&
                  <div  className='border rounded-lg overflow-hidden relative group'>
                    <div  onClick={() => deleteImage('image.id', 'thumbnail')} className='scale-0 group-hover:scale-100 hover:scale-110 transition-all duration-150 absolute top-0 right-0 '>
                      <XMarkIcon className='h-6 w-6  hover:text-red-600 transition-all duration-150 ' />
                    </div>
                    {
                      uploadedImages.thumbnail_path ? <img className='h-32 w-32' src={networkImage(uploadedImages.thumbnail_path)} alt="" />
                      :
                      <p className='p-5 text-xs'>No Thumbnail Image</p>
                    }
                    
                  </div>
                
              }
              
              {
                !loading && uploadedImages.images.map((image, index) =>(
                  <div key={index} className='border rounded-lg overflow-hidden relative group'>
                    <div  onClick={() => deleteImage(image.id, '')} className='scale-0 group-hover:scale-100 hover:scale-110 transition-all duration-150 absolute top-0 right-0 '>
                      <XMarkIcon className='h-6 w-6  hover:text-red-600 transition-all duration-150 ' />
                    </div>
                    <img className='h-32 w-32' src={networkImage(image.path)} alt="" />
                  </div>
                ))
              }
              </div>


            </div>
{/*             
            <div   className='flex flex-col gap-5'>

              <FileUpload title="thumbnail" fileListFunction={getThumbnail} multiple={false}  />
              <FileUpload title="additional_images" fileListFunction={getAdditionalImages} multiple={true} />
            </div> */}

            {/* filepond codes  */}
                        
            <div   className='flex flex-col gap-5'>
              {/* <div className="App">
                  <p className='text-base pb-2'>Upload Thumbnail</p>
                  <FilePond
                      files={files}
                      onprocessfile={onProcessFile}
                      onupdatefiles={onUpdateFiles}
                      allowMultiple={false}
                      server={imagePath}
                      name="image"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
              

              </div>*/}


              <div className="App">
                  <p className='text-base pb-2'>Upload Image</p>
                  <FilePond
                      files={additionalFiles}
                      onprocessfile={onProcessAdditionalFile}
                      onupdatefiles={onUpdateAdditionalFiles}
                      allowMultiple={true}
                      server={imagePath}
                      name="image"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
              

              </div>
            </div> 

          </div>
          <div className='flex gap-3 pt-5'>

              <button className='bg-blue-400 p-3 text-xs text-white rounded-lg' onClick={() => submitImages(false)} >Submit Image</button>
              
              <button  onClick={() => submitImages(true)} 
              className={isDataExist ? 'bg-blue-400 p-3 text-xs text-white rounded-lg' : 'hidden'}
                >Save & Exit</button>
          </div>

            <div className='flex gap-3 pt-5 flex-wrap'>
              

              {
                allImages?.map((image, index) =>(
                  <div className='relative'>
                    <img onClick={() => imageSelection(image.folder)} className='h-32 w-32 cursor-pointer' src={networkImage(image.folder+'/'+image.filename)} alt="" />
                    <div className=" cursor-pointer" onClick={()=>destroyTemporaryImage(image.folder)} >
                      Delete
                    </div>
                    {(() => {
        if (image.folder == thumbnail) {
          return (
            <div>Thumbnail</div>
          )
        } else {
          return (
            <div>Others</div>
          )
        }
      })()}
                    
                  </div>

                ))}
               
            </div>
          
    
    </ProductContainer>
  )
}
