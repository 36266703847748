import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageForgetPassword from "containers/ForgetPassword/ForgetPassword";
import AdminPageLogin from "containers/AdminPageLogin/AdminPageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import Dashboard from "containers/Dashboard/Dashboard";
import UserLayout from "../components/Layouts/user";
import AdminLayout from "../components/Layouts/admin"
import Products from "containers/Products/ProductIndex";
import CreateProduct from "containers/Products/Create";
import ViewProduct from "containers/Products/View";
import { Toaster } from "react-hot-toast";
import ProtectedAdmin from "components/ProtectedAdmin";
import ProtectedUser from "components/ProtectedUser";

// product edit 
import ImageEdit from "containers/Products/Edit/Image/Image";
import AttributeEdit from "containers/Products/Edit/Attribute/Attribute";
import VariationEdit from "containers/Products/Edit/Variation/VariationPage";
import FeatureEdit from "containers/Products/Edit/Feature/Feature";
import DescriptionEdit from "containers/Products/Edit/Description";
import PaginateProducts from "containers/Products/Page/PaginateProducts";

// delivery charges 
import DeliveryIndex from "containers/DeliveryCharges/Index";
import PaginateDeliveryCharges from "containers/DeliveryCharges/Page/PaginateDeliveryCharges";
import CreateDeliveryCharge from "containers/DeliveryCharges/Create";
import EditDeliveryCharge from "containers/DeliveryCharges/Edit/EditDeliveryCharge";
import OrderDetails from "containers/AccountPage/OrderDetails";


// order 
import OrderIndex from "containers/OrderPage/OrderIndex"
import OrderView from "containers/OrderPage/OrderView";
import PaginateOrders from "containers/OrderPage/Page/PaginateOrders";


// return 
import ReturnIndex from "containers/ReturnPage/ReturnIndex"
import PaginateReturns from "containers/ReturnPage/Page/PaginateReturns";

export const commonPages = [
  { path: "/", component: PageHome },
  { path: "/home2", component: PageHome2 },
  { path: "/home3", component: PageHome3 },
  //
  { path: "/home-header-2", component: PageHome },
  { path: "/product-detail/:productSlug", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/page-collection-2", component: PageCollection2 },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-collection", component: PageSearch },
  //
  // { path: "/account", component: AccountPage },
  // { path: "/account-savelists", component: AccountSavelists },
  // { path: "/account-change-password", component: AccountPass },
  // { path: "/account-billing", component: AccountBilling },
  //
  // { path: "/cart", component: CartPage },
  // { path: "/checkout", component: CheckoutPage },
  // //
  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },
  // //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/admin-login", component: AdminPageLogin },
  { path: "/forgot-pass", component: PageForgetPassword },
  { path: "/subscription", component: PageSubcription },
  //
  { path: "/dashboard", component: Dashboard },
];


export const userPages= [
  
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
]



export const adminPages = [
  { path: "/", component: Dashboard },

  
  { path: "/return", component: ReturnIndex },
  { path: "/return/page/:pageNo", component: PaginateReturns },


  { path: "/orders", component: OrderIndex },
  { path: "/orders/:orderId", component: OrderView },
  { path: "/orders/page/:pageNo", component: PaginateOrders },

  { path: "/products", component: Products },
  { path: "/products/page/:pageNo", component: PaginateProducts },
  { path: "/products/create", component: CreateProduct },
  { path: "/products/:productId", component: ViewProduct },
  { path: "/products/edit/:productId", component: DescriptionEdit },
  { path: "/products/edit/image/:productId", component: ImageEdit },
  { path: "/products/edit/attribute/:productId", component: AttributeEdit },
  { path: "/products/edit/variation/:productId", component: VariationEdit },
  { path: "/products/edit/feature/:productId", component: FeatureEdit },

  
  { path: "/delivery-charges", component: DeliveryIndex },
  { path: "/delivery-charges/create", component: CreateDeliveryCharge },
  { path: "/delivery-charges/page/:pageNo", component: PaginateDeliveryCharges },
  { path: "/delivery-charges/edit/:chargeId", component: EditDeliveryCharge },
];
// EditDeliveryCharge
const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Routes >
        <Route path="/" element={<UserLayout />}>
        {commonPages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
        </Route>

      
        
        <Route path="/cart" element={<ProtectedUser  Component={CartPage} />} />
        <Route path="/checkout" element={<ProtectedUser  Component={CheckoutPage} />} />

        <Route path="/customer" element={<ProtectedUser  Component={UserLayout} />}>
          <Route path="/customer/account" element={<AccountPage />} />
          <Route path="/customer/account-change-password" element={<AccountPass />} />
          <Route path="/customer/account-my-order" element={<AccountOrder />} />
          <Route path="/customer/account-savelists" element={<AccountSavelists />} />
          <Route path="/customer/account-billing" element={<AccountBilling />} />
          <Route path="/customer/account-my-order/:orderNo" element={<OrderDetails />} />
        </Route>
        
        

        <Route path="/admin" element={<ProtectedAdmin  Component={AdminLayout} />}>

          {adminPages.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={'/admin'+path} />;
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
