import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { networkImage } from 'utils/network'
import usePost from "hooks/usePost";
import { useParams } from "react-router-dom";

export default function OrderDetails({detailInfo, title, backLink, returnDate, reloadPage}) {

  const { orderId } = useParams();
    const apiVersion = process.env.REACT_APP_API_VERSION;
  const [submitData, handlePost] = usePost("", {})
  const [resolveData, setResolveData] = useState({})
    let resolveApi = apiVersion + "/admin/return-resolve/"+orderId;

    console.log(returnDate)
  const sendReturnReq = async () => {
      
    await handlePost(resolveApi, resolveData).then((response) => {
        console.log(response)
      if (response.res.status == 200) {
        
        reloadPage()
      }
    })
    
  }
  return (
  <div className="overflow-hidden bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6 flex justify-between">
      <h3 className="text-base font-semibold leading-6 text-darkGray/90">
        {title}
      </h3>
      {
          returnDate ? 
          <div className='flex  justify-center items-center gap-2'>
            <input type="date" onChange={(e) => setResolveData({date: e.target.value})}/>
            <button onClick={() => sendReturnReq()} className='p-2 bg-green-400 hover:bg-green-700 transition-all duration-150 text-white rounded-lg text-xs'>Resolve Return Request</button>
        </div>
        : <></>
      }
        
      <Link to={backLink} className='p-2 bg-blue-400 hover:bg-blue-700 transition-all duration-150 text-white rounded-lg'>Go Back</Link>
    </div>
    <div className="">
        {
            detailInfo.map((info, index) => (
        <dl key={index}>


            {
                info.type == 'text' ? 
                <div
                v-if="item.type == 'text'"
                className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt className="text-sm font-medium text-darkGray/60 uppercase">
                      {info.key}
                  </dt>
                  <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
                      {info.value}
                  </dd>
                </div>
                :
                <div
                v-if="item.type == 'image'"
                className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                    <dt className="text-sm font-medium text-darkGray/60 uppercase">
                    {info.key }
                    </dt>
                    <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
                        <img
                        src={networkImage(info.value)}
                        className="w-20 h-20 flex-shrink-0 object-fit"
                        />
                    </dd>
                </div>

            }

        </dl>
            ))
        }

      {/* <dl v-for="item in props.data" :key="item.key">
        <div
          v-if="item.type == 'text'"
          className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            {{ $t(item.key) }}
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            {{ item.value }}
          </dd>
        </div>
        <div
          v-if="item.type == 'image'"
          className="border-b border-b-overlay/5 bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt className="text-sm font-medium text-darkGray/60 uppercase">
            {{ item.key }}
          </dt>
          <dd className="mt-1 text-sm text-black font-medium sm:col-span-2 sm:mt-0">
            <img
              :src="networkImage(item.value)"
              className="w-20 h-20 flex-shrink-0 object-fit"
            />
          </dd>
        </div>
      </dl> */}
    </div>
  </div>
  )
}
