import { networkGet } from "utils/network";
import {  useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
// import { open, close, insertToastData } from '../../features/toast/toastSlice';
import { open, close, insertToastData } from "features/toast/toastSlice";


const useIndex = (url) => {

  const [index, setIndex] = useState(null);
  const [indexLoading, setIndexLoading] = useState(true)
    
  const dispatch = useDispatch();
  const {token} = useSelector((state) => state.auth)

  async  function handleClick(api) {

    try {

      const response = await  networkGet({
          method: 'GET',
          path:api,
          heads:{
            Authorization: `Bearer ${token}`
          },
      })
      setIndexLoading(false)
      setIndex(response)
      
      
      if (response.status == 200) {
        var toastData = 
          {
            type : 'success',
            message : response.message,
            title : response.message 
          }
        dispatch(insertToastData(toastData))
      }else{
        var toastData = 
          {
            type : 'error',
            message : response.message,
            title : response.message 
          }
        dispatch(insertToastData(toastData))
      }
      // dispatch(logout(token))
      return response
    } catch (error) {
      setIndexLoading(false)
      setIndex(error)
        throw error;
    }
    
    }

  return [index, indexLoading, handleClick];
};

export default useIndex;