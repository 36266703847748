import { networkGet } from "utils/network";
import {  useState } from "react";


const useGet = (url) => {

  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(true)

  async  function handleClick(api) {
      

      try {
        const response = await networkGet({
        method: 'GET',
        path: api,
        heads: {
          'Accept': 'application/json',
          },
        })
        setData(response)
        setDataLoading(false)
        return response

      } catch (error) {
        setData(error)
        setDataLoading(false)
        throw error
      }

    }
  return [data,  dataLoading ,handleClick];
};

export default useGet;