import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import useGet from 'hooks/useGet';
import useIndex from 'hooks/useIndex';
import Table from   'components/Table/Table'
import useNetworkImage from 'hooks/useNetworkImage';
import { networkImage } from 'utils/network';
import ActionCell from 'components/Table/ActionCell';
import Pagination from 'components/Pagination/Pagination';
import usePost from 'hooks/usePost';
import Filter from 'components/FormInput/Filter';

export default function OrderIndex() {
    const apiVersion = process.env.REACT_APP_API_VERSION;
    const [submitData, handlePost] = usePost("", {}) 

    const pageData = {
        title: "Orders",
        filterTitle: "Search Orders"
    }
    const crudLink = "/admin/orders";
    const [path, setPath] = useState(apiVersion + "/admin/orders")

    const [orders, ordersIsLoading, callOrders] = useIndex('')
    const [totalPages, setTotalPages] = useState(0)    
    
   
    let tableHeader = [
      { field: "order_number", title: "order_number", sortable: true, arrange: true },
      { field: "status", title: "status", sortable: true, arrange: true },
      { field: "name", title: "Name", sortable: true, arrange: true },
      { field: "phone_number", title: "phone_number", sortable: true, arrange: true },
      { field: "sub_total", title: "sub_total", sortable: true, arrange: true },
      { field: "address", title: "address", sortable: true, arrange: true },
      { field: "created_at", title: "Date", sortable: false, arrange: false },
    ]


    let filters = [
      { field: "order_number", title: "order_number", sortable: false, arrange: true },
    ]


    useEffect(() => {
      dataLoad(path)
    }, [])


    const dataLoad = (api) => {
      callOrders(api).then((response) => {
        setTotalPages(response.res.data.last_page)
        console.log(response.res.data.data)
      })
    }

    const pageRefresh = () => {
      callOrders(path)
    }




    const filter = (dt) => {
      
        if (dt.search === "") {
          dataLoad(path);
        } else {
          let searchInput = dt.search;
          let field = dt.fieldName;
          console.log(searchInput, field)
          let filterApi = path + "?filter[" + field + "]=" + searchInput;
          if (dt.fieldName) {
            dataLoad(filterApi)
          }
      }
    }


        const changeStatus = (e, id ) => {
      let featuredApi = apiVersion+'/admin/order-status/'+id
      handlePost(featuredApi, {"status": e.target.value}).then((response) => {
        dataLoad(path)
      })
    }
  return (
    <div className=''>
        <div className='flex justify-between items-end pb-5 px-6'>
            <Filter data={filters} label={pageData.filterTitle} updateInput={(search) => filter(search)} resetPage={() => dataLoad(path)}/>
 
        </div>

        <div>
          <Table headers={tableHeader} title={pageData.title}>
                <tbody className="divide-y divide-gray-200 bg-white">


                  { orders?.res.data.data.map((orders, index) => (
                    <tr key={index} >
                      <ActionCell 
                        rowId={orders['id']} 
                        viewLink={crudLink + '/' + orders['order_number']}
                        editLink=""
                        deleteLink=""
                        refresh={pageRefresh} 
                      />
                      
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { orders["order_number"]}
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                          <div className='flex  items-center justify-center h-20'>
                            <select onChange={(event) => changeStatus(event, orders.order_number)} name="featured" id="featured">
                              <option selected={orders["status"] ? false : true} >Seletec One</option>
                              <option  
                              selected={orders["status"] =="Pending" ? true : false} value="Pending">Pending</option>
                              <option selected={orders["status"] =="Processing" ? true : false} value="Processing">Processing</option>
                              <option selected={orders["status"] =="Delivered" ? true : false}  value="Delivered">Delivered</option>
                              
                            </select>
                          </div>
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { orders["name"].substring(0, 20)}
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { orders["phone_number"]}
                      </td>

                      
                      
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { orders["sub_total"]}
                      </td>


                      
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { orders["state"]}
                      </td>
                      <td  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                        { 
                          orders["created_at"].substring(0, 10)
                        }
                      </td>
                    </tr>
                  ))}
                  
                </tbody>
          </Table>
          <div className='flex justify-center pt-8'>

            <Pagination pageRoot="/admin/orders/page/" totalPages={totalPages}/> 

          </div>
        </div>
    </div>
  )
}






// OrderIndex