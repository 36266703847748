import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import VariationImage from 'components/VariationImage/VariationImage';
import ProductContainer from 'containers/ProductPage/ProductContainer';
import useIndex from 'hooks/useIndex';
import usePost from 'hooks/usePost';
import React, { useState , useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { networkImage } from 'utils/network';
class Variation {
  constructor(
    id,
    variation_string,
    image,
    variation_code,
    quantity,
    sku,
    price,
    discount_type,
    discount,
    attributes,
    is_default
  ) {
    this.id = id;
    this.variation_string = variation_string;
    this.image = image;
    this.variation_code = variation_code;
    this.quantity = quantity;
    this.sku = sku;
    this.price = price;
    this.discount_type = discount_type;
    this.discount = discount;
    this.attributes = attributes;
    this.is_default = is_default;
  }
  setIsDefaut(is_default) {
    this.is_default = is_default;
  }
}

export default function VariationPage() {
  const apiVersion = process.env.REACT_APP_API_VERSION
  const {productId} = useParams();
  const [productAttribute, attributeLoading, fetchAttribute] = useIndex('')

  const [data, submitVariation] = usePost("", {})
    
  const [loading, setLoading] = useState(true)
  var variationIndex = [];
  const [variationState , setVariationState] = useState([])
  const [showAdditionalImages, setShowAdditionalImages] = useState(0)

  const navigate = useNavigate() 
  let quantity = 0
  const pageData= {
    title: "Edit Variation"
  }
  const tabs = [
      { name: 'Edit Product', href: '/admin/products/edit/'+productId, current: false },
      { name: 'Image Upload', href: '/admin/products/edit/image/'+productId , current: false },
      { name: 'Attribute', href: '/admin/products/edit/attribute/'+productId, current: false },
      { name: 'Variation', href: '/admin/products/edit/variation/'+productId, current: true },
      { name: 'Features', href: '/admin/products/edit/feature/'+productId, current: false },
  ]

  const dataLoad = async () => {
    setLoading(true)
    
    await fetchAttribute(apiVersion + "/admin/products/" + productId + "/variations").then(response => {
        var networkAttributes = [];
        let networkAttributesHolder = response.res.data.product_attributes;
        quantity = response.res.data.quantity !== null ? response.res.data.quantity : 0
        for (
        let index = 0;
        index < networkAttributesHolder.length;
        index++
      ) {
        var networkOptions = [];
        const attribute = networkAttributesHolder[index];
        for (let j = 0; j < attribute["options"].length; j++) {
          const option = attribute["options"][j];
          networkOptions.push({
            id: option["id"],
            option: option["label"],
          });
        }
        networkAttributes.push({
          attribute_id: attribute["id"],
          attribute_name: attribute["name"],
          options: networkOptions,
        });
        
      }

      const optionCombinations = generateOptionCombinations(networkAttributes);
      console.log('optionCombinations',optionCombinations);
      const networkVariations = response.res.data.variations
      console.log('networkVariations',networkVariations);
      variationIndex = []
      
      for (const combination of optionCombinations) {
      var myOldVariation = networkVariations.find((obj) => {
          return obj["variation_code"] == combination["code"];
        });
        if (myOldVariation != undefined) {
          variationIndex.push(
            new Variation(
              myOldVariation["id"],
              combination["combination"],
              myOldVariation["image"],
              combination["code"],
              myOldVariation["quantity"],
              myOldVariation["sku"],
              myOldVariation["price"],
              myOldVariation["discount_type"],
              myOldVariation["discount"],
              combination["combinationObject"],
              myOldVariation["is_default"]
            )
          );
        } else {
          variationIndex.push(
            new Variation(
              "",
              combination["combination"],
              "#",
              combination["code"],
              "1",
              "SKU",
              "1",
              "Flat",
              "1",
              combination["combinationObject"],
              false
            )
          );
        }
        

      }
      variationIndex[0].setIsDefaut(true)
      setVariationState(variationIndex);
      
      
      setLoading(false)
             
    })
    .catch(error => {
        console.error('Error:', error);
    });

  }
  const generateOptionCombinations = (attributes) => {
  const combinations = [];
  function generate(attributes, index, currentCombination) {
    if (index === attributes.length) {
      let code = "";
      let combination = "";
      let combinationObject = [];

      for (const attribute of attributes) {
        const attributeId = attribute.attribute_id;
        const optionName = currentCombination[attributeId];
        const optionId = attribute.options.find(
          (option) => option.option === optionName
        ).id;

        code += attributeId.toString() + optionId.toString();
        combination += attribute.attribute_name + ": " + optionName + ", ";
        combinationObject.push({
          attribute: attributeId,
          value: optionId,
        });
      }

      combination = combination.substring(0, combination.length - 2);

      combinations.push({
        code: productId + "" + code,
        combination: combination,
        combinationObject: combinationObject,
      });

      return;
    }

    const attributeId = attributes[index].attribute_id;
    const options = attributes[index].options;

    for (const option of options) {
      const optionName = option.option;

      currentCombination[attributeId] = optionName;

      generate(attributes, index + 1, currentCombination);

      delete currentCombination[attributeId];
    }
  }

  generate(attributes, 0, {});

  return combinations;
}
  useEffect(() => {
    if (loading) {
      dataLoad();
    }else{
      return 
    }
  },[loading])

  
  const handleInputChange = (event, index, field, variationString) => {

      const newInputValues = [...variationState]
      newInputValues[index]["variation_string"] = variationString

      newInputValues[index][field] = event.target.value;
      setVariationState(newInputValues)
      console.log(newInputValues[index], variationString)
  };


  const submit = async ()=> {
  console.log(variationState)
  // return variationState
    var formData = {
      has_variation: "true",
      unit: quantity,
      variations: JSON.stringify(variationState),
    }

    console.log(formData)

    let path = 
    await submitVariation(apiVersion+"/admin/products/"+productId+"/variations", formData).then(response => {
      if (response.status == 200) {

        navigate('/admin/products/edit/feature/'+productId)
      }
    })
    
  }



  const handleImageChange = (path, index, variationString) => {

    const newInputValues = [...variationState]
    newInputValues[index]["variation_string"] = variationString

    newInputValues[index]["image"] = path;
    setVariationState(newInputValues)
    
  };


  return (
    <ProductContainer title={pageData.title} tabData={tabs}>
      <div>
        {
          variationState.map((variation , index)=>(
          <div key={index} className="flex flex-col gap-2 mb-3">
            <div className=' flex justify-start items-center gap-2'>
              <p className='text-lg font-medium uppercase'>{variation.variation_string}</p>
            </div>

            <div className='grid grid-cols-4 sm:grid-cols-7 gap-2'>
              <div className='flex flex-col  w-full'>
                <label htmlFor={`variation-value-${index}`}>Quantity:</label>
                <input
                className="border border-gray-600 focus:outline-none focus:border-blue-500 text-gray-600 rounded-md w-full"
                  type="text"
                  name={`variation-value-${index}`}
                  defaultValue={variation.quantity}
                  onChange={(event) => handleInputChange(event, index, 'quantity', variation.variation_string)}
                />
              </div>


              <div className='flex flex-col col-span-2 w-full'>
                <label htmlFor={`variation-value-${index}`}>Price:</label>
                <input
                className="border border-gray-600 focus:outline-none focus:border-blue-500 text-gray-600 rounded-md w-full"
                  type="text"
                  name={`variation-value-${index}`}
                  defaultValue={variation.price}
                  onChange={(event) => handleInputChange(event, index, 'price',  variation.variation_string)}
                />
              </div>


              <div className='flex flex-col  w-full'>
                <label htmlFor={`variation-value-${index}`}>Discount:</label>
                <input
                className="border border-gray-600 focus:outline-none focus:border-blue-500 text-gray-600 rounded-md w-full"
                  type="text"
                  name={`variation-value-${index}`}
                  defaultValue={variation.discount}
                  onChange={(event) => handleInputChange(event, index, 'discount',  variation.variation_string)}
                />
              </div>


              <div className='flex flex-col col-span-2 sm:col-span-1 w-full'>
                <label htmlFor={`variation-value-${index}`}>Discount Type: </label>
                <select className="border border-gray-600 focus:outline-none focus:border-blue-500 text-gray-600 rounded-md w-full" name={`variation-value-${index}`} id="" 
                  onChange={(event) => handleInputChange(event, index, 'discount_type',  variation.variation_string)}>
                  {
                    variation.discount_type == 'Flat'?
                  <> 
                    <option selected defaultValue={true}>Flat</option>
                    <option >Percentage</option>
                  </>
                  : 
                  <> 
                    <option >Flat</option>
                    <option selected defaultValue={true}>Percentage</option>
                  </>
                  }

                  

                
                </select>

              </div>

              <div className='flex flex-col col-span-2 w-full'>
                <label htmlFor={`variation-value-${index}`}>Sku:</label>
                <input
                className="border border-gray-600 focus:outline-none focus:border-blue-500 text-gray-600 rounded-md w-full"
                  type="text"
                  name={`variation-value-${index}`}
                  defaultValue={variation.sku}
                  onChange={(event) => handleInputChange(event, index, 'sku',  variation.variation_string)}
                />
              </div>

              <div className='flex flex-col col-span-2 sm:col-span-1 w-full'>
                <label htmlFor={`variation-value-${index}`}>Default: </label>
                <select className="border border-gray-600 focus:outline-none focus:border-blue-500 text-gray-600 rounded-md w-full" name={`variation-value-${index}`} id="" 
                  onChange={(event) => handleInputChange(event, index, 'is_default',  variation.variation_string)}>
                  {
                    variation.is_default ? 
                  <option selected value={true} defaultValue={true}>Yes</option>
                  : 
                  <option value={true} defaultValue={true}>Yes</option>
                  }

                  {
                    !variation.is_default ? 
                  <option selected value={false} defaultValue={false}>No</option>
                  : 
                  <option value={false} defaultValue={false}>No</option>
                  }

                
                </select>

              </div>
              


            <div className='flex items-end  w-full'>
              {
                variation.image !== "#"? 
                <div className='group flex items-center gap-5  w-fit border p-1 relative'>

                
                  <img onClick={() => setShowAdditionalImages(index)} className='max-h-28' src={networkImage(variation.image)} alt="" />
                  <button onClick={() => handleImageChange("#", index, variation.variation_string)} className=' p-1 rounded text-xs text-white w-fit absolute top-0 right-0  scale-0 group-hover:scale-110   transition-all duration-150'><XMarkIcon className='  h-5 w-5 text-black'  /></button>
                </div>
                : 
                <button onClick={() => setShowAdditionalImages(index)} className='bg-blue-500 p-2 px-1 text-xs  text-white'>Select image</button>
              }
                
              </div>

            </div>

            <div>
              {
                showAdditionalImages == index ? 
                <VariationImage selectedImage={(imgPath) =>  handleImageChange(imgPath, index, variation.variation_string)}/> 
                : 
                <></>
              }
              {/* handleInputChange(event, index, 'is_default',  variation.variation_string) */}
            </div>
          </div>
          ))
        }
        <div className='flex justify-between'>
          <button   onClick={() => submit()} className='bg-blue-600 px-4 py-2 border border-blue-600 text-white font-medium rounded-md mt-8 indigo-600 hover:bg-white hover:text-blue-600 transition-all duration-200'>Submit</button>
        
          
        </div>
      </div>
    </ProductContainer>

  )
}


