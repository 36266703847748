import { networkPost } from "utils/network";
import axios from 'axios';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'


const usePost = (url, body) => {
  const [submitData, setSubmitData] = useState(null);
  const {token} = useSelector((state) => state.auth)
  //   useEffect(() => {
  //   networkPost({
  //       method: 'POST',
  //       path:url,
  //       heads:{
  //           'Accept': 'application/json',
  //           'Authorization': `Bearer ${token}`
  //       },
  //       body: body
  //   })
  //   .then((response) => setSubmitData(response));


  // }, [url]);


    async function handleClick(api, formData) {

      try {
        const response = await networkPost({
          method: 'POST',
          path:api,
          heads:{
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: formData
        })
        return response
      } catch (error) {
         throw error;
      }
        // await networkPost({
        //   method: 'POST',
        //   path:api,
        //   heads:{
        //   'Accept': 'application/json',
        //   'Authorization': `Bearer ${token}`
        // },
        // body: formData
        // })
        // .then((response) => {
        //   setSubmitData(response.res)
        // });
      }

  return [submitData, handleClick];
}

export default usePost;