import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'




export default function ProtectedAdmin(props) {

  const {authenticated, role} = useSelector((state) => state.auth)
    const {Component} = props
    const navigate = useNavigate()
    

    useEffect(() => {
      if (authenticated) {
          if (role !== 'admin') {
              navigate('/')
          }
      }else{
          navigate('/login')
      }
    
    }, [authenticated])
    


  return (
    <div>
        <Component />
    </div>
  )
}
