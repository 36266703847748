import Label from "components/Label/Label";
import useGet from "hooks/useGet";
import useIndex from "hooks/useIndex";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";

// interface Props {
//   isActive: boolean;
//   onCloseActive: () => void;
//   onOpenActive: () => void;
//   onAddressChange: () => void;
// }

const ShippingAddress = ({
  isActive,
  onCloseActive,
  onOpenActive,
  onAddressChange,
  onPlaceOrder,
  allInputs
}) => {
  const apiVersion = process.env.REACT_APP_API_VERSION;
  const [deliveryCharges, chargeIsLoading, callDeliveryCharges] = useGet('')
    
  const [states, setStates] = useState([])
  const [stateLoad, setStateLoad] = useState(false)
  const [error, setError] = useState({
    full_name: "",
    phone_no: "",
    address: "",
    shipping_charge: "",
    pin_code: "",
    city: "",
    state: ""
  })

  const navigate = useNavigate()
  let statesApi = apiVersion+'/delivery-charge-settings'

  useEffect(() => {
    dataLoad()
  }, [])

  const dataLoad = () => {
    setStateLoad(false)
    callDeliveryCharges(statesApi).then((response) => {
      if(response.status == 200){
        
        
        setStates(response.res.data)
        setStateLoad(true)
        onAddressChange(response.res.data[0]["charge"], "shipping_charge")
        
        onAddressChange(response.res.data[0]["state"], "state")
      }
    })
  }


  const orderPlacing = () => {
    
    setError({
      full_name: "",
      phone_no: "",
      address: "",
      shipping_charge: "",
      pin_code: "",
      city: "",
      state: ""
    })
    Object.keys(allInputs).map((field) => {
      
      if (allInputs[field] == '') {
        let emptyField = error
        emptyField[field] = field + ' field is empty' 

        setError({...emptyField})
      }
    })
    
    onPlaceOrder()
  }

  const stateChange = (e, field) => {
    
    states.map(state => {
      if (state.state === e.target.value) {
        // console.log(state.charge)
        
        onAddressChange(state.charge, "shipping_charge")
        onAddressChange(state.state, "state")
      }
    })
  }
    
  const renderShippingAddress = () => {

    
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">SHIPPING ADDRESS</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            {/* <div className="font-semibold mt-1 text-sm">
              <span className="">
                St. Paul's Road, Norris, SD 57560, Dakota, USA
              </span>
            </div> */}
          </div>
          <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Change
          </ButtonSecondary>
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
            isActive ? "block" : "hidden"
          }`}
        >
          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Full name <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5" onChange={(e) => onAddressChange(e.target.value, "full_name")} required={true} />
              <p className="text-xs text-red-500 pl-2">
              {
                error["full_name"] ? error["full_name"] : ""
              }
              </p>
            </div>
          </div>
          


          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Phone No <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5"  onChange={(e) => onAddressChange(e.target.value, "phone_no")} required={true}/>

              <p className="text-xs text-red-500 pl-2">
              {
                error["phone_no"] ? error["phone_no"] : ""
              }
              </p>
            </div>
          </div>

          

          
          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
            <div className="flex-1">
              <Label className="text-sm" >Address <span className="text-red-600">*</span> </Label>
              <Input
                className="mt-1.5"
                placeholder=""
                type={"text"}
                onChange={(e) => onAddressChange(e.target.value, "address")}
                required={true}
              />

              <p className="text-xs text-red-500 pl-2">
              {
                error["address"] ? error["address"] : ""
              }
              </p>
            </div>
            {/* <div className="sm:w-1/3">
              <Label className="text-sm">Apt, Suite * <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5" defaultValue="55U - DD5 " />
            </div> */}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">City <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5"  onChange={(e) => onAddressChange(e.target.value, "city")} required={true}/>

              <p className="text-xs text-red-500 pl-2">
              {
                error["city"] ? error["city"] : ""
              }
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Select State <span className="text-red-600">*</span> </Label>
              <Select className="mt-1.5"  onChange={(e) => stateChange(e, "shipping_charge")} required={true}>
                
                  {
                    states?.map((state, index) => (
                      <option  key={index} value={state.state}>
                        <p>{state.state} </p>
                      </option>
                    ))
                  }
              </Select>
              
            </div>
          </div>


          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">Pin Code <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5"  onChange={(e) => onAddressChange(e.target.value, "pin_code")} required={true}/>
              <p className="text-xs text-red-500 pl-2">
              {
                error["pin_code"] ? error["pin_code"] : ""
              }
              </p>
            </div>
          </div>


          {/* ============ */}
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">City <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5" defaultValue="Norris" />
            </div>
            <div>
              <Label className="text-sm">Country <span className="text-red-600">*</span> </Label>
              <Select className="mt-1.5" defaultValue="United States ">
                <option value="United States">United States</option>
                <option value="United States">Canada</option>
                <option value="United States">Mexico</option>
                <option value="United States">Israel</option>
                <option value="United States">France</option>
                <option value="United States">England</option>
                <option value="United States">Laos</option>
                <option value="United States">China</option>
              </Select>
            </div>
          </div> */}

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            {/* <div>
              <Label className="text-sm">Phone No <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5"  />
            </div>
            <div>
              <Label className="text-sm">Postal code <span className="text-red-600">*</span> </Label>
              <Input className="mt-1.5" defaultValue="2500 " />
            </div> */}
          </div>

          {/* ============ */}
          {/* <div>
            <Label className="text-sm">Address type <span className="text-red-600">*</span> </Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <Radio
                label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                id="Address-type-home"
                name="Address-type"
                defaultChecked
              />
              <Radio
                label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                id="Address-type-office"
                name="Address-type"
              />
            </div>
          </div> */}

          {/* ============ */}
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={() => orderPlacing()}
            >
              Place Order
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={() => navigate('/')}
            >
              Cancel
            </ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
