import React, { useState, useEffect } from 'react'

import useIndex from 'hooks/useIndex';


export default function Dashboard() {

  // api base
    const apiVersion = process.env.REACT_APP_API_VERSION;
    
    const returnCountApi =  apiVersion + "/admin/return-count"

    const [returnCount, ordersIsLoading, callReturnCount] = useIndex('')


    const dataLoad = (api) => {
      callReturnCount(api).then((response) => {
        // setTotalPages(response.res.data.last_page)
        console.log(response.res.data)
      })
    }

    useEffect(() => {
      dataLoad(returnCountApi)
    }, [])
  return (
    <div>
        <p className='text-3xl text-center text-red-700'>Welcome to DaInch Dashboard</p>
        <div className='pt-20'>
          {/* widgets  */}
          <div className='bg-red-600 text-white w-fit p-3 rounded-lg'>
            <p>Return Count</p>
            <p className='text-5xl font-semibold'>{returnCount?.res.data}</p>
          </div>
        </div>
    </div>
  )
}
