import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
       auth: {
         authenticated:  false,
         userid:  null,
         token:  null,
         username:  null,
         type:  null,
         profileImage:  null,
         phone:  null,
       },
  },
  reducers: {

    setUser: (state, action) => {

      const { userid, authenticated, token, username, type, profileimage, phone} = action.payload

      state.auth.userid = userid;
      state.auth.authenticated = authenticated;
      state.auth.token = token;
      state.auth.username = username;
      state.auth.type = type;
      state.auth.profileImage = profileimage;
      state.auth.phone = phone
            //   console.log(this.state);
    },

    //     setUser: ({ userid, authenticated, token, username, type, profileimage, phone}) => {
    //   state.auth.authenticated = authenticated
      
    // }
    getUser:(state)   => {
        return state.auth
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, getUser } = authSlice.actions

export default authSlice.reducer