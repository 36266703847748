import React, { FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";


import useGet from "hooks/useGet";
import { useParams } from "react-router-dom";
import { networkImage } from "utils/network";
import { useSelector, useDispatch } from 'react-redux'
import { setProduct, setSubTotal } from "features/cart/cartSlice";
import CommonModal from 'components/Modal/CommonModal';
import Input from "shared/Input/Input";




const ProductDetailPage = ({ className = "" }) => {
  const apiVersion = process.env.REACT_APP_API_VERSION;
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const [modal, setModal] = useState(false)

  const [variantActive, setVariantActive] = useState(0);
  const [sizeSelected, setSizeSelected] = useState(sizes ? sizes[0] : "");
  const [quantitySelected, setQuantitySelected] = useState(1);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);

  const {productSlug} = useParams()
  const [data,  dataLoading ,getDetails] = useGet('')
  const [productState, setProductState] = useState()
  const dispatch = useDispatch();
  const {products, subTotal} = useSelector((state) => state.cart)
  const [currentImage, setCurrentImage] = useState()

  let path = apiVersion+'/product/'+productSlug

  var product = {}
  var variationCode = ""
  var variationAttribute = {}
  var colorAttributes = []
  var sizeAttributes = []

  var colorOptions = []
  var sizeOptions =[]


  const [colorOptionsState, setColorOptionsState] = useState()
  const [sizeOptionsState, setSizeOptionsState] = useState()
  const [finalCode, setFinalCode] = useState()
  const [colorCode, setColorCode] = useState("")
  const [sizeCode, setSizeCode] = useState("")
  const [variations, setVariations] = useState([])
  const [price, setPrice] = useState(0)


  const [userMeasurementString, setUserMeasurementString] = useState('')
  // const measurement = useRef(null);
  const measurement = {
    chest: "",
    waist: "",
    hips: "",
    shoulder: ""
  }


  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getDetails(path).then((response) => {
      if(response.status == 200){
        setProductState(response.res.data)
        product= response.res.data

        // setPrice(response.res.data.default_variation)
        console.log(response.res.data.variations[0].image)
        setCurrentImage(response.res.data.variations[0].image)
        setPrice(response.res.data.default_variation.discounted_price ? response.res.data.default_variation.discounted_price : response.res.data.default_variation.price)
        defaultVariationSetup()

        attributeSetup()
        setLoading(false)
        setVariations(product.variations)
 
      }
    })
  }, [])

  const attributeSetup =( )=> {
        product.product_attributes.map((attribute, index) => {
        if (attribute.slug == 'color') {
              // console.log('color index', index)
              colorAttributes = attribute
              
        }else if(attribute.slug == 'size'){
              // console.log('size index', index)
              sizeAttributes = attribute
        }
      })
      availableOptions()
  }

  const availableOptions = () => {
    colorOptions = []
    sizeOptions = []
    colorAttributes.options.map((option, index) =>{
      colorOptions.push({code: option.attributable_id+""+ option.id, label: option.label, value: option.value})
    })

    sizeAttributes.options.map((option, index) =>{
      sizeOptions.push({code: option.attributable_id+""+ option.id, label: option.label, value: option.value})
    })
    
    setColorOptionsState(colorOptions)
    setSizeOptionsState(sizeOptions)
  }

  const [selectedVariation, setSelectedVariation] = useState(null)

  const codeGenerator = (color, size) => {
    // console.log(variationCode)
    // console.log(variationCode)

    var colCode =color !== null ? color : colorCode 
    var sizCode = size !== null ? size : sizeCode


    console.log(colCode, sizCode);
    // console.log(colorCode, sizeCode)
    variationCode = productState.id+""+colCode+""+sizCode
    // console.log(variationCode)
    

    variations.map((variation)=>{
      if(variation.variation_code ==  variationCode){  
        // console.log(variation);
        setCurrentImage(variation.image)
        console.log(variation.image);
        if (variation.discounted_price) {
          setPrice(variation.discounted_price)
        }
        else{
          setPrice(variation.price)
        }
        setSelectedVariation(variation)
        
      }
    })

    setFinalCode(variationCode)
    setColorCode(colCode)
    setSizeCode(sizCode)
  }



  const defaultVariationSetup = () => {
      variationCode = product.default_variation.variation_code
      setFinalCode(variationCode)
      variationAttribute = product.default_variation.variation_attributes


      variationAttribute.map((attribute, index) => {
        if (attribute.product_attribute.slug == 'color') {
              setColorCode(attribute.attribute_option.attributable_id+""+  attribute.attribute_option_id)

        }else if(attribute.product_attribute.slug == 'size'){
              setSizeCode(attribute.attribute_option.attributable_id+""+  attribute.attribute_option_id)
              
        }
      })
      

  }


  const addToCart = () => {
    let variationStr 
    let variationRaw 
    productState.variations.map((variation) => {
      if (variation.variation_code == finalCode) {
        variationStr = variation.variation_string
        variationRaw = variation
      }
      
    })
    let productInfo = {
      product: productState,
      quantity: quantitySelected,
      code: finalCode,
      variationString: variationStr,
      variationRaw: variationRaw,
      measurement: measurement
    }

    dispatch(setProduct(productInfo))
    setModal(false)
  };




  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          quantitySelected={quantitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderVariants = () => {
    // if (!variants || !variants.length) {
    //   return null;
    // }

    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Color:
            {/* <span className="ml-1 font-semibold">
              {variants[variantActive].name}
            </span> */}
          </span>
        </label>
        <div className="grid grid-cols-5 gap-2">
          { colorOptionsState?.map((color, index) => ( 
            <div
              key={index}
              onClick={() => codeGenerator(color.code, null)}
              className={colorCode == color.code ? `relative flex-1 max-w-[100px] h-10 sm:h-14 rounded-full border cursor-pointer text-white shadow-lg border-white-600 shadow-gray-400 scale-105 transition-all duration-150`: 'relative flex-1 max-w-[100px] h-10 sm:h-14 rounded-full border-2 cursor-pointer text-black transition-all duration-150'}
              
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0 flex justify-center items-center bg-white text-xs text-black">
                {color.label.toString().toUpperCase()}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    // if (!allOfSizes || !sizes || !sizes.length) {
    //   return null;
    // }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              {/* <span className="ml-1 font-semibold">{sizeSelected}</span> */}
            </span>
          </label>
          {/* <p
            onClick={() => setModal(true)}
            className="text-primary-6000 hover:text-primary-500 cursor-pointer"
          >
            Input your measurement
          </p> */}
        </div>
        <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-3">
          {sizeOptionsState?.map((size, index) => {
            // const isActive = size === sizeSelected;
            // const sizeOutStock = !sizes.includes(size);
            return (
              <div
                key={index}
                onClick={() => codeGenerator(null, size.code)}
                // className={`"relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                // text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0" `}
                
                className={sizeCode == size.code? 
                  'relative h-10 sm:h-11 rounded-2xl flex items-center justify-center text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer border-2 border-[#7E211A] text-[#7E211A]' 
                  : 
                  'relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer'}
              >
                {size.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {productState?.name}
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              className="flex gap-2"
              price={price}
              selectedVariation={selectedVariation ?  selectedVariation : productState?.default_variation}
            />
            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>

            <div className="flex items-center">
              <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </a>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div>
            </div>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        
        <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div>

        <div className="flex space-x-3.5 mt-3 text-center sm:mt-5 ">
                      

              <div className="text-start">
                <p className="text-base font-semibold text-red-800 pb-5">Input your measurement</p>
                

                <div className="grid grid-cols-2 gap-3">
                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Chest
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 "
                      onChange={(e)   => measurement['chest'] = e.target.value}
                    />
                  </label>

                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Waist
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 "
                      onChange={(e)   => measurement['waist'] = e.target.value}
                    />
                  </label>

                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Hips
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 "
                      onChange={(e)   => measurement['hips'] = e.target.value}
                    />
                  </label>

                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Shoulder
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 "
                      onChange={(e)   => measurement['shoulder'] = e.target.value}
                    />
                  </label>
            
                </div>

              </div>
            
        </div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={quantitySelected}
              onChange={setQuantitySelected}
            />
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={() => {
              addToCart()
            }}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary>
        </div>

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo data={[{
    name: "Description",
    content:
    productState?.description,
  }]}/>

  

        {/* ---------- 6 ----------  */}
        <div className="hidden xl:block">
          <Policy />
        </div>
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-2xl font-semibold">Product Features</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">

          <ul className="flex items-center gap-10 ">
            {
              productState?.features.map((feature, index)=>{
                return <li key={index}> {feature.name.toString().toUpperCase()} -  {feature.value }</li>
              })
            }
          </ul>
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className="">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> 4,87 · 142 Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem />
            <ReviewItem
              data={{
                comment: `I love the charcoal heavyweight hoodie. Still looks new after plenty of washes. 
                  If you’re unsure which hoodie to pick.`,
                date: "December 22, 2021",
                name: "Stiven Hokinhs",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `The quality and sizing mentioned were accurate and really happy with the purchase. Such a cozy and comfortable hoodie. 
                Now that it’s colder, my husband wears his all the time. I wear hoodies all the time. `,
                date: "August 15, 2022",
                name: "Gropishta keo",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `Before buying this, I didn't really know how I would tell a "high quality" sweatshirt, but after opening, I was very impressed. 
                The material is super soft and comfortable and the sweatshirt also has a good weight to it.`,
                date: "December 12, 2022",
                name: "Dahon Stiven",
                starPoint: 5,
              }}
            />
          </div>

          <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          {/* CONTENT */}
          <div className="w-full lg:w-[55%] ">
            {/* HEADING */}
            <div className="relative">
              <div className="aspect-w-16 aspect-h-16">
                <img
                  src={networkImage(currentImage ? currentImage : productState?.thumbnail_path)}
                  className="w-full rounded-2xl object-cover"
                  alt="product detail 1"
                />
              </div>
              {renderStatus()}
              {/* META FAVORITES */}
              <LikeButton className="absolute right-3 top-3 " />
            </div>
            <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
              {productState?.images.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                  >
                    <img
                      src={networkImage(item.path)}
                      className="w-full rounded-2xl object-cover"
                      alt="product detail 1"
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {/* SIDEBAR */}
          <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            {renderSectionContent()}
          </div>
        </div>

        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          <div className="block xl:hidden">
            <Policy />
          </div>

          {renderDetailSection()}

          <hr className="border-slate-200 dark:border-slate-700" />

          {renderReviews()}

          <hr className="border-slate-200 dark:border-slate-700" />

          {/* OTHER SECTION */}
          <SectionSliderProductCard
            heading="Customers also purchased"
            subHeading=""
            headingFontClassName="text-2xl font-semibold"
            headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
          />

          {/* SECTION */}
          <div className="pb-20 xl:pb-28 lg:pt-14">
            <SectionPromo2 />
          </div>
        </div>
      </main>

      {/* size modal  */}
        {
          modal ? 
          <CommonModal>
            <div className="mt-3 text-center sm:mt-5 ">
              <div className="absolute top-3 right-3 cursor-pointer" onClick={() => setModal(false)}>
                <XMarkIcon   className="w-5 h-5 text-gray-800 hover:text-red-700" />
              </div>
              <div>
                <p className="text-base font-semibold">Input your measurement</p>


                <div>
                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Chest
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 w-fit"
                      onChange={(e)   => measurement['chest'] = e.target.value}
                    />
                  </label>

                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Waist
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 w-fit"
                      onChange={(e)   => measurement['waist'] = e.target.value}
                    />
                  </label>

                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Hips
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 w-fit"
                      onChange={(e)   => measurement['hips'] = e.target.value}
                    />
                  </label>

                  <label className="flex flex-col justify-start gap-2  py-2">
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm text-slate-500 font-semibold">
                      Shoulder
                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1 w-fit"
                      onChange={(e)   => measurement['shoulder'] = e.target.value}
                    />
                  </label>
            
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => addToCart()}
                      >
                        Add to cart
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </button>
                  </div>
              </div>
            </div>
          </CommonModal>
          :
          <></>
        } 

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
    </div>
  );
};

export default ProductDetailPage;